// ==========================================================================
// Hero
// ==========================================================================
.hero {
  position: relative;

  .map {

  }
}

.hero--plain {
  .inner {
    padding-top: 6vh;
    padding-bottom: 6vh;
  }
}

.hero--large {
  .inner {
    padding-top: 6vh;
    padding-bottom: 6vh;
  }
}

.hero--flat {
  position: relative;
  background-color: #3F3F3F;
  min-height: 350px;
}

.hero--builder {
  position: relative;
  z-index: 1;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon--service {
    display: inline-block;
    vertical-align: middle;
  }

  .wrapper {
    position: relative;
    z-index: 2;
  }

  .logo {
    width: 100%;
    max-width: 400px;

    img {
      max-height: 150px;
      width: auto;
      height: auto;
      max-width: 100%;
    }

    &--small {
      max-width: 230px;
    }
  }

  .inner {
    position: relative;
    z-index: 3;
    padding: 50px 15px;
    width: 100%;

    &--pn {
      position: initial !important;
    }

    h1 {
      font-size: 4rem;
      position: relative;
      z-index: 1;

      em.color--accent, em.color--blue {
        font-style: normal;
        font-weight: bold;
      }

      @include breakpoint("small") {
        font-size: 5.6rem;

        &.small {
          font-size: 4.5rem;
        }
      }
    }

    &.img--auto {
      img {
        margin: 0 auto;
      }
    }

    &.img--center {
      .wrapper {
        display: flex;
        align-items: center;
      }
    }

    .u-text-right img {
      margin-left: auto;
    }

    .u-text-center p {
      @include breakpoint(small) {
        padding: 0 70px;
      }
      @include breakpoint(medium) {
        padding: 0 220px;
      }
      @include breakpoint(large) {
        padding: 0 290px;
      }
    }
  }

  p {
    margin: 0;
    position: relative;
    z-index: 1;
  }

  .people {
    position: absolute;
    bottom: 0;
    right: 15px;
    display: none;
    z-index: 2;

    @include breakpoint(medium) {
      right: 20px;
      margin: 0 auto;
      display: block;

      img {
        margin: 0 0 0 auto;
      }
    }

    img {
      max-height: 450px;
      max-width: 500px;
      widtH: 100% !important;
    }
  }

  &_minimal {
    min-height: 0;
  }

  &_half {
    min-height: 50vh;
  }

  &_near-full {
    height: 85vh;
    min-height: 85vh;
    min-height: calc(85vh - 52px);

    @include breakpoint(large) {
      min-height: calc(85vh - 72px);
    }
  }

  &_full {
    height: 100vh;
    min-height: 100vh;
    min-height: calc(100vh - 52px);

    @include breakpoint(large) {
      min-height: calc(100vh - 72px);
    }
  }

  &.hero__background--image {

    .bkg {

    }
  }

  &_search {
    border-bottom: 4px solid #D90573;
    align-items: flex-end;

    .inner {
      padding: 75px 0;
    }
  }
}

.hero--grad {
  &::after {
    opacity: 0.84;
    content: "";
    @include cover;
    z-index: 2;
    background-image: linear-gradient(-58deg, rgba(34, 28, 53, 0.00) 0%, #221C35 100%);
  }
}

.hero--simple {

  .inner {
    img {
      max-width: 250px;
      margin-bottom: 20px;

      &.small {
        max-width: 100px;
      }
    }

    h1 {
      font-size: 2.8rem;

      @include breakpoint(small) {
        font-size: 4.2rem;
      }

      .accent--color {
        font-style: normal;
        font-weight: bold;
      }
    }
  }
}

.hero--team {
  padding-bottom: 0 !important;

  .inner {
    padding-bottom: 0 !important;
  }

  .team-member {
    position: relative;
    margin-top: 30px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: 0.5;
    }
  }
}

.op::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  z-index: 1;
}

.op--purple::after {
  background-color: #221C34;
}

.op--purple-grad::after {
  opacity: 0.93;
  background-color: transparent;
  background-image: linear-gradient(-58deg, rgba(34, 28, 53, 0.00) 0%, #221C35 100%);
}

.op--lightest::after {
  opacity: 0.1
}

.op--lighter::after {
  opacity: 0.3
}

.op--hue::after {
  opacity: 0.5
}

.op--hue--main {
  opacity: 0.5
}

.op--darker::after {
  opacity: 0.7
}

.op--darkest::after {
  opacity: 0.9
}

.hero__info {
  padding: 40px 0 20px;

  .or {
    padding-top: 20px;
  }

  &--filters {
    padding: 40px 0;
  }
}

.hero__extra {
  position: relative;
  z-index: 999;
  border-top: 3px solid white;
  background-color: palette(brand, purple);

  &--top {
    padding-top: 10px;
  }

  .value {
    padding-top: 20px;
  }

  .hero__lots {
    padding: 20px 0;

    label {
      display: block;
    }

    select {
      padding: 10px 30px 10px 0;
    }
  }

  .partners {
    padding-top: 15px;
    padding-bottom: 15px;

    .partner__item {
      display: inline-block;
      margin-right: 15px;
    }

    img {
      padding: 5px;
      height: 52px;
    }
  }

  .hero__details {
    background-color: palette(brand, blue);
    position: relative;
    margin-left: gutter(-1.5);
    margin-right: gutter(-1.5);
    padding-right: gutter(1.5);
    padding-left: gutter(1.5);

    .detail__trigger {
      font-size: 1.6rem;
      font-weight: bold;
      color: palette(brand, purple);
      padding: 15px 0;
    }

    .icon {
      margin-left: 15px;
      transform: rotate(180deg);
      transition: transform .3s;
    }


    .detail__dropdown {
      background-color: palette(brand, blue);
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.15s ease-out;

      .inner {
        padding: 15px gutter(1.5);
      }

      &.open {
        transition: max-height 0.25s ease-in;
        max-height: 600px;
      }
    }

    &--private {
      background-color: palette(brand, private);

      .icon path {
        fill: white;
      }

      .detail__trigger {
        color: white;
      }

      .detail__dropdown {
        background-color: palette(brand, private);
        color: white;
      }
    }
  }

  &--cpv {
    background-color: white;
    color: #333;
    border-bottom: 1px solid #D8D8D8;
    padding: 20px 0 25px;
  }

  &--support {
    select {
      border-radius: 0;
      border: none;
      min-height: 70px;
      padding-left: 0;
      padding-right: 0;
    }

    .btn__hold {
      padding: 20px 0;
    }
  }
}

.hero__finder {


  .select {


    select {


      border-bottom: 1px solid white;
      color: palette(brand, blue);
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 0;
      border-radius: 0;
      margin: 10px 0 10px;
      background-image: url(/dist/img/icons/arrow-down-white.svg);
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 12px;

      @include breakpoint-max(small) {
        width: 100%;
      }
    }

    .checkbox {
      margin-top: 10px;
    }
  }
}

.news__author {
  position: absolute;
  bottom: 0;
  right: 15px;
  z-index: 0;
  display: none;

  img {
    max-height: 450px;
  }
}

.hero__icon-block {
  position: relative;
  z-index: 2;
  margin-top: 20px;
}

.hero--study {
  position: relative;

  .logo {
    width: 100%;
    max-width: 400px;

    img {
      max-height: 150px;
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }

  .wrapper {
    position: relative;
    z-index: 2;
  }

  .inner {
    position: relative;
    z-index: 3;
    padding: 50px 15px;
    width: 100%;
  }

  .content {
    background-color: rgba(34, 28, 53, 0.7);
    padding: 30px;
    color: white;

    .progress-indicator {
      strong {
        margin-right: 20px;
      }

      .progress-indicator__square {
        height: 16px;
        width: 16px;
        display: inline-block;
        margin-left: 5px;
        border-radius: 50%;
        background: #D77103;

        &:first-of-type {
          margin-left: 0;
        }
      }

      &.progress-indicator--4 {
        .progress-indicator__square {
          background: #F7AF37;
        }
      }

      &.progress-indicator--5 {
        .progress-indicator__square {
          background: #3DA742;
        }
      }
    }

  }
}


.hero-home {
  overflow: hidden;
  position: relative;
  background: white;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
  }

  .flickity-viewport {
    z-index: 4;
  }

  .socials {
    display: none;
  }


  .item {
    position: relative;
    z-index: 5;
    padding-top: 15vh;
    min-height: 100vh;

    .swoosh {
      display: block;
      @include cover();
      z-index: 3;
      background-image: url(/dist/img/bkg/homepage-white-swoosh.svg);
      background-repeat: no-repeat;
      background-position: bottom -2px center;
      background-size: 100%;
    }

    .slider-dots {
      display: block;
      width: 10px;
      height: 10px;
      background: palette(brand, blue);
      border-radius: 50%;

      &.active {
        background: white;
      }
    }

    &.is-selected {
      z-index: 6;
    }

    &::before {
      content: "";
      z-index: 2;
      @include cover();
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.35) 81%);
    }


    .wrapper {
      position: relative;
      z-index: 3;
    }
  }
}


.hero--netzero {
  padding-bottom: 0 !important;

  .inner {
    padding-bottom: 0 !important;
  }

  .cyclist {
    display: none;
  }
}

@include breakpoint-max(small) {
  .hero-home {
    .no-vid-small video {
      display: none;
    }
  }
}

.hero--article {
  .inner {
    padding: 100px 15px;
  }
}

@include breakpoint-max(medium) {
  .hero--builder {
    .wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@include breakpoint(small) {

  .hero__finder {


    .checkbox {
      margin-left: 15px;
    }

    .select {
      width: auto;
      display: inline-block;

      select {
        margin: 10px 10px 10px;
      }
    }
  }


  .hero--plain {
    .inner {
      padding-top: 15vh;
      padding-bottom: 15vh;
    }
  }

  .hero-home {



    .item {
      z-index: 4;
      padding-top: 22vh;
    }
  }

  .hero--large {
    .inner {
      padding-top: 22vh;
      padding-bottom: 22vh;
    }
  }

  .hero--study {

    .content img {
      max-width: 280px;
    }

    .content {
      padding: 40px 60px;
    }
  }

  .news__author {
    display: block;
  }

  .hero__info {
    .socials {
      text-align: right;
    }

    .or {
      padding: 0;
    }
  }

  .hero--team {

    .inner {
      padding-top: 0 !important;
    }

    .name {
      margin-bottom: 70px;
    }
  }

  .hero__extra {

    &--support {
      select {
        border-left: 3px solid white;
        border-right: 3px solid white;
        min-height: 90px;
        padding: 15px 49px 15px 25px;
      }
    }

    .value {
      min-height: 70px;
      padding-top: 30px;
    }

    .hero__details {
      height: 100%;
      border-left: 3px solid white;
      border-right: 3px solid white;
      display: flex;
      align-items: center;
      justify-content: center;

      .detail__trigger {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }
    }
  }

  .hero--builder {
    &.chunk--public-private-swooshes {
      padding-bottom: 100px !important;
    }
  }
}

@include breakpoint(medium) {


  .hero--netzero {
    border-bottom: 30px solid palette(brand, purple);

    &::after {
      content: none;
    }

    .content {
      min-height: 600px;
    }

    .g {
      align-items: flex-end;
    }

    .cyclist {
      display: block;
      text-align: center;
      transform: translateY(26px);

      img {
        max-width: 330px;
      }

    }
  }

  .hero-home {


    .socials {
      display: block;
      position: absolute;
      z-index: 10;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);

      .icon--social:hover {
        ellipse, circle {
          fill: white;
        }

        .invert {
          fill: white;
        }

        path {
          fill: palette(brand, blue)
        }
      }
    }

    .item {

      &::after {
        content: "";
        @include cover();
        z-index: 2;
        background-image: url(/dist/img/bkg/homepage-blue-swoosh.svg);
        background-repeat: no-repeat;
        background-position: right -2px center;
        background-size: cover;
      }
    }
  }

  .hero__extra {

    .partners {
      img {
        padding: 7px;
        height: 65px;
      }
    }

    &--support {
      .btn__hold {
        text-align: right;

        > * {
          text-align: left
        }
      }
    }


    .hero__details {
      position: relative;
      margin-left: gutter(-1.5);
      margin-right: gutter(-1.5);
      padding-right: gutter(1.5);
      padding-left: gutter(1.5);

      .detail__trigger {
        padding: 15px 0;
      }

      .icon {
        margin-left: 15px;
        transform: rotate(180deg);
        transition: transform .3s;
      }

      .detail__dropdown {
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.15s ease-out;

        .inner {
          padding: 15px gutter(1.5);
        }

        &.open {
          transition: max-height 0.25s ease-in;
          max-height: 600px;
        }
      }
    }
  }

  .hero--builder {
    &.chunk--public-private-swooshes {
      padding-bottom: 150px !important;
    }
  }
}