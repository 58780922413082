// ==========================================================================
// #SUPPORT
// ==========================================================================
.supportVid {
  max-width: 100%;
}

.support__hold {
  overflow: hidden;
  position: relative;
}

.support__bkg {
  @include bg-cover();
  padding-top: 170px;
}

.support__text {
  padding-bottom: 20px;
}

.support__buttons {
  display: none;

  .btn {
    background-color: transparent;

    &:hover {
      background-color: palette(brand, blue);
      border-color: palette(brand, blue);
      color: palette(brand, purple);
    }
  }
}

.sidebar {
  .logo__hold img {
    max-width: 205px;
  }
}

.ticklist {

  li {
    font-weight: 500;
    background-image: url('/dist/img/icons/blue-tick-box.svg');
    background-repeat: no-repeat;
    background-position: top 5px left;
    background-size: 25px;
    padding-bottom: 20px;
    padding-left: 35px;
  }
}

.support-slider__hold {
  background-color: palette(grey, x-light);
}

.support__triggers {
  display: flex;
  flex-wrap: wrap;

  .support__trig {
    flex-basis: 25%;
    transition: color .3s;
    padding-right: 20px;
    padding-bottom: 10px;
    cursor: pointer;

    .slide__title, .orb {
      display: none;
    }

    .count__hold {
      position: relative;
      padding-top: 15px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 5px;
        width: 100%;
        background-color: #B5B5B5;
        transition: background-color .3s, height .3s;
      }
    }

    .count {
      font-weight: 500;
    }

    &--active {
      color: palette(blue);

      .count__hold {

        &::before {
          background-color: palette(blue);
          height: 10px;
        }
      }
    }
  }
}

.spin {
  display: none;
}

.btn--active {
  border-color: white;
}

.team__controls {
  padding: 0;
  outline: none;
  background-color: transparent;
  border: none;
  top: 0;
  position: absolute;
  z-index: 10;

  &.next {
    right: 0;
  }

  &.previous {
    left: 0;
  }
}

.team__slider {
  position: relative;

  .slick-list {
    overflow: visible !important;
  }

  .slick-next, .slick-prev {
    height: 30px;
    width: 40px;
  }

  .slick-next {
    background-image: url('../images/icons/thin-arrow--right.svg');
  }

  .slick-prev {
    background-image: url('../images/icons/thin-arrow--left.svg');
  }

  .slick-arrow {
    opacity: 1;
    transition: all .3s;
    top: 20%;
  }

  .slick-disabled {
    opacity: 0;
  }

  .flickity-viewport {
    overflow: visible !important;
  }

  .flickity-prev-next-button {
    top: 0;
    transform: translateY(0);
  }
}

.team__slide {
  position: relative;
  opacity: 0.6;
  display: block;
  width: 100%;
  transition: opacity .3s;


  @include breakpoint(small) {
    width: 33% !important;
  }

  .image {
    font-size: 0;
  }


  .btn {
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
  }

  img {
    transform-origin: center bottom;
    transition: transform .3s, opacity .3s;


    @include breakpoint(medium) {
      img {
        transform: scale(0.8);
      }
    }
  }

  h2 {
    white-space: nowrap;
    opacity: 0;
    transition: opacity .3s;
  }

  @include breakpoint(medium) {
    .btn {
      display: none;
    }
  }


  .image__hold {

    &:hover {
      h2 {
        opacity: 1;
      }
    }
  }


  &.is-selected {
    opacity: 1;
    z-index: 2;

    h2 {
      opacity: 1;
    }

    img {
      opacity: 1;
    }

    @include breakpoint(medium) {
      img {
        transform: scale(1.15);
      }
    }
  }
}

.hero--support {
  padding-bottom: 0 !important;

  .inner {
    position: static !important;
  }
}

.hero__extra--support {

  select {
    width: 100%;
  }

  .hero__details {
    padding: 0 !important;
  }

  .btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    padding: 15px 2rem;
    font-size: 1.8rem;
    transition: background-color .3s;

    .icon {
      transform: rotate(0) !important;
    }
  }
}

.step {
  fill: #b2cde1 !important;
  transition: fill .3s;

  &--active {
    fill: #1397bb !important;
  }
}

@include breakpoint(medium) {


  .hero__extra--support {

    select {
      width: 400px;
    }
  }

  .support__buttons {
    position: relative;
    display: block;
    border-top: 4px solid palette(blue);
    padding: 40px 0;

    h3 {
      margin-bottom: 20px;
    }

    .btn {
      width: 100%;
      text-align: center;
      color: white;
      padding-left: 0;
      padding-right: 0;
    }

    .g__col {
      margin-bottom: 15px;
    }
  }
  .spin {
    display: block;
    margin-bottom: -177px;
    position: relative;
    font-size: 0;
    line-height: 1;
    z-index: 4;
    transition: transform .3s;

    &, svg {
      width: 420px;
      height: 420px;
    }
  }


  .slider-support__triggers--spinner {
    width: 50%;
  }

  .step-slider--spinner {


    .ticklist {
      padding-top: 60px;
    }
  }
}

@include breakpoint(large) {


  .step {
    fill: #b2cde1 !important;
    transition: fill .3s;

    &--active {
      fill: #1397bb !important;
    }
  }

  .support-slider__hold {
    background-color: transparent;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      border-top: 1px solid palette(blue);
      background-color: palette(grey, x-light);
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 67px;
    }

    &--small {

      .spin {
        transition: transform .3s;
      }

      .support__triggers {
        width: 50%;
      }

      &::before {
        height: 37px;
      }
    }
  }

  .support__triggers {

    .support__trig {
      text-align: center;
      flex: 1;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 10px;

      .slide__title, .orb {
        display: block;
        font-weight: bold;
      }

      .count {
        color: #9A9A9A;
        padding-bottom: 10px;
        display: block;
      }

      .orb {
        position: relative;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin: 0 auto;

        &::after, &::before {
          content: "";
          border-radius: 50%;
        }

        &::after {
          display: block;
          width: 45px;
          height: 45px;
          border: 1px solid palette(blue);
          transition: opacity .3s, transform .3s;
        }

        &::before {
          width: 8px;
          height: 8px;
          background-color: palette(blue);
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: left top;
          transform: translate(-50%, -50%);
          transition: transform .3s;
        }
      }

      .slide__title {
        color: #9A9A9A;
        padding-top: 8px;
        font-size: rem-calc(16);
      }

      .count__hold {
        padding-top: 0;
        //padding-bottom: 20px;
        padding-bottom: 5px; //test
        &::before {
          content: none; //test
          top: initial;
          bottom: 0;
          height: 8px;
          background-color: transparent;
        }
      }


      &--active {
        .count__hold {
          &::before {
            background-color: palette(blue);
          }
        }

        .orb::before {
          transform: scale(2) translate(-50%, -50%);
        }

        .orb::after {
          transform: scale(0);
          opacity: 0.5;
        }

        .count, .slide__title {
          color: palette(blue);
        }
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .support-slider__hold--small {

    &::before {
      height: 37px;
    }
  }
}