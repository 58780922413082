/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/

.g {
  width: 100%;
  lost-flex-container: row;

  > * {
    width: 100%;
  }
}

.g--ng {
  > * {
    width: 50%;
    flex-basis: 50%;
  }
}

.g__1 {
}

/*
  Footer grid modification
*/
.g--footer {
}

.g--gutter {
  .g__1-2 {
  }

}

.g--no-gutter {

}

.g__1-2 {
  lost-column: 1/2;
}

.g__1-3 {
  lost-column: 1/3;
}

.g--gutter-img {
  .g__1-2 {
    lost-column: 1/2 2 20px;
  }
}

.g--gutter-0 {
  .g__1-2 {
    lost-column: 1/2 2 0;
  }

  .g__1-3 {
    lost-column: 1/3 3 0;
  }

  .g__1-4 {
    lost-column: 1/4 4 0;
  }

}

.g__ac {
  align-items: center;
}

.g__jc {
  justify-content: center;
}

.g--grid {
  > * {
    margin-bottom: 30px;
  }
}

@include breakpoint(small) {
  .g--gutter-0 {

    .gs__1-1 {
      width: 100%;
    }

    .gs__1-2 {
      lost-column: 1/2 2 0;
    }

    .gs__1-3 {
      lost-column: 1/3 3 0;
    }

    .gs__1-6 {
      lost-column: 1/6 6 0;
    }

    .gs__5-6 {
      lost-column: 5/6 6 0;
    }

    .gs__m-1-6 {
      lost-move: -1/6 6 0;
    }

    .gs__m5-6 {
      lost-move: 5/6 6 0;
    }

    .gs__2-10 {
      lost-column: 2/10 10 0;
    }

    .gs__4-10 {
      lost-column: 4/10 10 0;
    }
  }

  .g__ab, .gs__ab {
    align-items: flex-end;
  }

  .gs__jsb {
    justify-content: space-between;
  }

  .gs__ac {
    align-items: center;
  }

  .gs__jc {
    justify-content: center;
  }

  .gs__1-2 {
    lost-column: 1/2;
  }

  .gs__1-3 {
    lost-column: 1/3;
  }

  .gs__2-3 {
    lost-column: 2/3;
  }

  .gs__1-4 {
    lost-column: 1/4;
  }

  .gs__3-4 {
    lost-column: 3/4;
  }

  .gs__2-5 {
    lost-column: 2/5;
  }
  .gs__3-5 {
    lost-column: 3/5;
  }

  .gs__1-6 {
    lost-column: 1/6;
  }

  .gs__5-6 {
    lost-column: 5/6;
  }
  .gs__3-10 {
    lost-column: 3/10;
  }
  .gs__4-10 {
    lost-column: 4/10;
  }

  .gs__6-10 {
    lost-column: 6/10;
  }

  .gs__7-10 {
    lost-column: 7/10;
  }

  .gs__2-12 {
    lost-column: 2/12;
  }
  .gs__4-12 {
    lost-column: 4/12;
  }

  .gs__5-12 {
    lost-column: 5/12;
  }

  .gs__6-12 {
    lost-column: 6/12;
  }

  .gs__7-12 {
    lost-column: 7/12;
  }

  .gs__10-12 {
    lost-column: 10/12;
  }


  .gs__m1-4 {
    lost-move: 1/4 4 0;
  }

  .gs__m1-2 {
    lost-move: 1/2 2 0;
  }
  .gs__m-1-2 {
    lost-move: -1/2 2 0;
  }

}


@include breakpoint(medium) {
  .gm__ac {
    align-items: center;
  }

  .gm__ab {
    align-items: flex-end;
  }

  .gm__jc {
    justify-content: center;
  }

  .gm__jsb {
    justify-content: space-between;
  }

  .gm__1-1 {
    lost-column: 1/1 1;
  }

  .g--gutter-0 {
    .gm__1-4 {
      lost-column: 1/4 4 0;
    }
  }

  .g--footer {
    .gm__5-12 {
      lost-column: 5/12 12 gutter(5);
    }

    .gm__7-12 {
      lost-column: 7/12 12 gutter(5);
    }
  }

  .gm__1-2 {
    lost-column: 1/2;
  }

  .gm__1-3 {
    lost-column: 1/3;
  }

  .gm__2-3 {
    lost-column: 2/3;
  }

  .gm__1-4 {
    lost-column: 1/4;
  }

  .gm__3-4 {
    lost-column: 3/4;
  }

  .gm__1-5 {
    lost-column: 1/5;
  }

  .gm__2-5 {
    lost-column: 2/5;
  }

  .gm__3-5 {
    lost-column: 3/5;
  }

  .gm__4-5 {
    lost-column: 4/5;
  }


  .gm__1-10 {
    lost-column: 1/10;
  }

  .gm__2-10 {
    lost-column: 2/10;
  }

  .gm__3-10 {
    lost-column: 3/10;
  }

  .gm__4-10 {
    lost-column: 4/10;
  }


  .gm__6-10 {
    lost-column: 6/10;
  }

  .gm__7-10 {
    lost-column: 7/10;
  }

  .gm__8-10 {
    lost-column: 8/10;
  }
  .gm__9-10 {
    lost-column: 9/10;
  }

  .gm__3-12 {
    lost-column: 3/12;
  }

  .gm__4-12 {
    lost-column: 4/12;
  }

  .gm__5-12 {
    lost-column: 5/12;
  }

  .gm__6-12 {
    lost-column: 6/12;
  }

  .gm__7-12 {
    lost-column: 7/12;
  }

  .gm__8-12 {
    lost-column: 8/12;
  }

  .gm__9-12 {
    lost-column: 9/12;
  }


  .gm__m1-3 {
    lost-move: 1/3;
  }

  .gm__m-2-3 {
    lost-move: -2/3;
  }


  .gm__m1-10 {
    lost-move: 1/10;
  }

  .gm__m-8-10 {
    lost-move: -8/10;
  }

  .gm__m6-12 {
    lost-move: 6/12;
  }

  .gm__m-7-12 {
    lost-move: -7/12;
  }

}

@include breakpoint(large) {

  .gl__1-6 {
    lost-column: 1/6;
  }

  .gl__1-2 {
    lost-column: 1/2;
  }

  .gl__1-3 {
    lost-column: 1/3;
  }

  .gl__2-3 {
    lost-column: 2/3;
  }

  .gl__1-4 {
    lost-column: 1/4;
  }

  .gl__3-4 {
    lost-column: 3/4;
  }

  .gl__1-5 {
    lost-column: 1/5;
  }

  .gl__2-8 {
    lost-column: 2/8;
  }

  .gl__6-8 {
    lost-column: 6/8;
  }

  .gl__2-7 {
    lost-column: 2/7;
  }

  .gl__5-7 {
    lost-column: 5/7;
  }
  .gl__3-10 {
    lost-column: 3/10;
  }

  .gl__4-10 {
    lost-column: 4/10;
  }

  .gl__6-10 {
    lost-column: 6/10;
  }

  .gl__7-10 {
    lost-column: 7/10;
  }

  .gl__m-6-10 {
    lost-move: -6/10;
  }

  .gl__m4-10 {
    lost-move: 4/10;
  }

  .gl__4-12 {
    lost-column: 4/12;
  }

  .gl__8-12 {
    lost-column: 8/12;
  }


  .g--gutter-0 {
    .gl__4-10 {
      lost-column: 4/10 10 0;
    }
    .gl__6-10 {
      lost-column: 6/10 10 0;
    }
  }
}
