.tabs {

  .nav {
    display: flex;
    flex-wrap: wrap;
  }

  .tab__content {
    display: none;

    &--active {
      display: block;
    }
  }

  .nav__item {
    background-color: transparent;
    border: 0;
    flex-basis: 50%;
    transition: color .3s;
    text-align: left;
    padding: 0 20px 10px 0;
    cursor: pointer;
    outline: none;
    margin: 0;
    font-size: 1.8rem;

    .inner {
      padding-top: 20px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 5px;
        width: 100%;
        background-color: #B5B5B5;
        transition: background-color .3s, height .3s;
      }
    }


    &--active {
      color: palette(brand, purple);
      .inner {
        &::before {
          background-color: palette(blue);
          height: 10px;
        }
      }
    }
  }
}

@include breakpoint(small) {
  .tabs {

    .nav__item {
      flex: 1;
    }
  }
}