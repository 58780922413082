//=====================================
// #CHUNK
//=====================================
.chunk {
  position: relative;

  &--front {
    z-index: 2;
  }
}

.overflow--hidden {
  overflow: hidden;
}

.chunk--blue {
  background-color: palette(blue);
}

.chunk--blue-dark {
  background-color: #024B5F;
}

.chunk--purple {
  background-color: palette(brand, purple);

  &-overlay {
    position: relative;

    &::before {
      content: "";
      @include cover();
      background-image: url("/dist/img/bkg/half-height-swoosh-overlay.svg");
      background-size: cover;
      z-index: 2;
    }
  }
}


.chunk--blue-play {
  background-color: #1C4663;
}


.chunk--purple-dark {
  background-color: #1B162B;
}

.chunk--pink {
  background-color: palette(pink);
}

.chunk--grey-x-light {
  background-color: palette(grey, x-light);
}

.chunk--grey-dark {
  background-color: palette(grey, dark);
}

.chunk--purple-left {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: 100%;
    width: 100vw;
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: palette(brand, purple);
  }

}

.chunk--purple--grad {
  position: relative;

  .wrapper {
    z-index: 3;
  }

  &::before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(71, 60, 100, 0.00) 1%, rgba(36, 30, 55, 0.94) 82%, #221C34 100%);
  }
}

.chunk--purple--half {
  position: relative;

  @include breakpoint-max(small) {
    padding-top: 25px;

    h2, h1 {
      color: white;
      padding-top: 6vh;
    }

    background-color: palette(brand, purple);
  }

  .g {
    position: relative;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background-color: palette(brand, purple);
  }
}

@include breakpoint-max(medium) {
  .chunk--purple--bottom-half {
    background-color: palette(brand, purple);
    padding-top: 25px;

    h2, h1 {
      color: white;
      padding-top: 6vh;
    }

    p {
      color: white;
    }
  }

  .chunk--purple--top-half {
    background-color: palette(brand, purple);
    //padding-top: 25px;

    p {
      color: white;
    }
  }
}


.chunk--grey-dark--half {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background-color: palette(grey, dark);
  }
}

.chunk--grey-x-dark {
  background-color: palette(grey, x-dark);
}

.chunk--black {
  background-color: palette(black);
}

.chunk--public {
  background-color: palette(brand, blue);
}

.chunk--private {
  background-color: #5F2167 !important;
}

@include breakpoint(medium) {

  .chunk--purple--bottom-half, .chunk--purple--top-half {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 60%;
      background-color: palette(brand, purple);
    }
  }


  .chunk--purple--bottom-half {
    &::before {
      bottom: 0;
    }

    p {
      color: white;
    }
  }

  .chunk--purple--top-half::before {
    top: 0;
  }


  .chunk--grey--bottom-half, .chunk--grey--top-half {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 60%;
      background-color: palette(grey, dark);
    }
  }

  .chunk--grey--bottom-half {
    &::before {
      bottom: 0;
    }

    p {
      color: white;
    }
  }

  .chunk--grey--top-half::before {
    top: 0;
  }


  .chunk-half--white-grey-x-light--md {
    position: relative;

    &::before {
      content: "";
      background-color: palette(grey, x-light);
      position: absolute;
      height: 50%;
      bottom: 0;
      left: 0;
      width: 100%;
      right: 0;
    }
  }
}

.chunk--framework-pull-up {
  position: relative;
  z-index: 4;

  @include breakpoint(medium) {
    margin-bottom: -200px;
  }
}

.chunk--framework-pull-down {
  padding-top: 6vh;
  @include breakpoint(medium) {
    margin-top: -12vh;
    padding-top: calc(6vh + 200px);
  }
}

.chunk--overlay {
  position: relative;

  .img--background {
    z-index: 1;
  }

  .wrapper {
    z-index: 3;
  }

  &::before {
    content: "";
    position: absolute;
    @include cover();
    background-color: black;
    opacity: .7;
    z-index: 2;
  }

  @include breakpoint(small) {
    &::before {
      opacity: .4;
    }
  }
}

.chunk--waves-full {
  background-image: url("/dist/img/bkg/waves-full.svg");
  background-size: cover;
}

.chunk--waves-half {
  background-image: url("/dist/img/bkg/waves-half.svg");
  background-size: cover;
}

.chunk--waves-image-half,
.chunk--waves-image-full,
.chunk--swooshes,
.chunk--public-swooshes,
.chunk--private-swooshes,
.chunk--public-framework-swooshes,
.chunk--private-framework-swooshes,
.chunk--public-private-swooshes {
  .img--background {
    z-index: 1;
  }

  .inner {
    position: relative;
    z-index: 3;
  }

  &::before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &::after {
    z-index: 1 !important;
  }
}

.chunk--public-private-swooshes {
  &::before {
    background-image: url("/dist/img/bkg/public-private-swooshes.svg");
    background-repeat: no-repeat;
    background-position: bottom -2px center;
    background-size: 100%;
  }
}

.chunk--swooshes {
  &::before {
    background-image: url("/dist/img/bkg/default-swooshes.svg");
    background-repeat: no-repeat;
    background-position: bottom -2px center;
    background-size: 100%;
  }
}

.chunk--public-framework-swooshes {
  &::before {
    background-image: url("/dist/img/bkg/public-framework-swooshes.svg");
    background-repeat: no-repeat;
    background-position: bottom -2px center;
    background-size: 100%;
  }
}

.chunk--private-framework-swooshes {
  &::before {
    background-image: url("/dist/img/bkg/private-framework-swooshes.svg");
    background-repeat: no-repeat;
    background-position: bottom -2px center;
    background-size: 100%;
  }
}

.chunk--public-swooshes {
  &::before {
    background-image: url("/dist/img/bkg/public-swooshes.svg");
    background-repeat: no-repeat;
    background-position: bottom -2px center;
    background-size: 100%;
  }
}

.chunk--private-swooshes {
  &::before {
    background-image: url("/dist/img/bkg/private-swooshes.svg");
    background-repeat: no-repeat;
    background-position: bottom -2px center;
    background-size: 100%;
  }
}

.chunk--waves-image-half {
  &::before {
    background-image: url("/dist/img/bkg/waves-image-half.svg");
    background-size: cover;
  }
}

.chunk--grid-bkg {
  background-image: url("/dist/img/bkg/grid-bkg.svg");
  background-size: cover;
}

.chunk--waves-image-full {
  &::before {
    background-image: url("/dist/img/bkg/waves-image-full.svg");
    background-size: cover;
  }
}

.chunk--full {
  min-height: 100vh;
}

.chunk--panel {
  background-color: white;
  border-radius: 10px;
  padding: 30px;

  @include breakpoint(small) {
    padding: 50px;
  }

  @include breakpoint(medium) {
    padding: 70px 70px;
  }
}

.chunk--home {
  background-image: url("/dist/img/bkg/home-block.svg");
  background-size: cover;
  z-index: 2;
}

.home__stats {
  display: none;

  .item {
    width: 100%;
  }

  @include breakpoint(small) {
    display: block;
  }

  .h1 {
    font-size: 6.2rem;
    margin-bottom: 5px;
  }

  p {
    line-height: 1.5;
  }

  @include breakpoint(small) {
    padding-bottom: 28vh;
    > * {
      padding: 0 10px;
    }
  }
}

.home__content-block {
  @include breakpoint(small) {
    margin-top: -20vh;

    h2 {
      color: white;
    }
  }
}
