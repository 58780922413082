// ==========================================================================
// Inputs
// ==========================================================================
input {
  display: block;
  width: 100%;
  font-family: $body-font;
  border: solid 2px palette(brand, purple);
  border-radius: 30px;
  font-size: 1.5rem;
  margin-bottom: 20px;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  transition: background .3s, border .5s;

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed;

    & + .checkbox__label {
      opacity: 0.4;
    }
  }

}

.input--outline {
  border-color: white;
  border-radius: 30px;
  background-color: transparent;
  color: white;

  &:focus {
    border-color: palette(brand, blue);
    background-color: transparent !important;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0;
}

.input--hold {
  margin-bottom: 20px;
}

.input--file {
  p {
    white-space: nowrap;
    margin-bottom: 0;
  }
}

textarea {
  margin-bottom: 20px;
  font-family: $body-font;
  border: solid 2px palette(brand, purple);
  border-radius: 20px;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 20px 15px;
  outline: none;
  transition: background .3s, border .5s;

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:empty) {
    background-color: #fbfbfb;
  }
}

.input--full {
  width: 100%;
}

.reset {
  border: none;
  background-color: transparent;
  font-size: 1.8rem;

  .icon {
    margin-right: 5px;
  }

  &--white {
    color: white;
  }
}

select {
  font-family: $body-font;
  border: solid 2px palette(brand, purple);
  border-radius: 30px;
  background-color: transparent;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.2;
  max-width: 100%;
  padding: 15px 49px 15px 25px;
  outline: none;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 16px 16px;
  background-image: url('/dist/img/icons/arrow-down-black.svg');
  text-overflow: ellipsis;

  &::-ms-expand {
    display: none;
  }

  &.select--white {
    color: white;
    border-color: white;
    background-image: url('/dist/img/icons/arrow-down-white.svg');
  }

  &.light {
    color: #333;
    border-color: #D8D8D8;
    background-image: url('/dist/img/icons/arrow-down-blue.svg');
  }



  &[disabled]{
    opacity: 0.4;
  }

  option {
    color: black;
  }
}

select.select--defined {
  border-color: palette(brand, purple);
}

//=====================================
// #FORM-TYPES
//=====================================

//.freeform-row {
//
//  .freeform-column {
//    position: relative;
//
//    label {
//      margin-top: 25px;
//      margin-bottom: 0;
//      position: absolute;
//      opacity: .6;
//      pointer-events: none;
//      font-weight: 400;
//      font-size: 1.7rem;
//      left: 0;
//      top: 0;
//      transform: translateY(0);
//      transition: .25s ease-in-out;
//    }
//
//    input, textarea, select {
//      width: 100%;
//      border: none;
//      border-bottom: 1px solid #282828;
//      border-radius: 0;
//      padding: 10px 0 10px;
//      background-color: transparent;
//      color: white;
//      transition: border-color .3s;
//
//      &[type=file] {
//        border: none;
//        line-height: 1;
//        padding-top: 5px;
//        width: 100%;
//      }
//
//      &.active--input {
//        border-bottom: 1px solid #282828;
//      }
//    }
//
//    input, textarea {
//
//      &:focus ~ label, &.active--input ~ label {
//        transform: translateY(-30px);
//        font-size: 1.4rem;
//      }
//    }
//
//    select.active--input {
//      border-bottom: 1px solid #282828;
//
//      ~ label {
//        transform: translateY(-80px);
//        letter-spacing: 1px;
//        font-size: 14px;
//      }
//    }
//  }
//}


.input__hold--fancy {
  margin-top: 30px;
  margin-bottom: 0;
  position: relative;

  &.top-0 {
    margin-top: 0;
  }

  label {
    margin-top: 12px;
    margin-bottom: 0;
    position: absolute;
    color: #FFFFFF;
    opacity: .6;
    pointer-events: none;
    font-weight: 400;
    font-size: 18px;
    left: 0;
    top: 0;
    transform: translateY(0);
    transition: .25s ease-in-out;
  }

  input, textarea, select {
    width: 100%;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, .6);
    border-radius: 0;
    padding: 10px 0 10px;
    background-color: transparent;
    color: white;
    transition: border-color .3s;

    &[type=file] {
      border: none;
      line-height: 1;
      padding-top: 5px;
      width: 100%;
    }

    &.active--input {
      border-bottom: 2px solid rgba(255, 255, 255, 1);
    }
  }

  input, textarea {

    &:focus ~ label, &.active--input ~ label {
      transform: translateY(-30px);
      letter-spacing: 1px;
      font-size: 14px;
    }
  }

  select {
    width: 100%;
    transition: border .5s;
  }

  select.active--input {
    border-bottom: 2px solid rgba(255, 255, 255, 1);

    ~ label {
      transform: translateY(-80px);
      letter-spacing: 1px;
      font-size: 14px;
    }
  }

  &--black {

    input, textarea, select {
      border-bottom: 2px solid rgba(0, 0, 0, .6);
      color: black;

      &.active--input {
        border-bottom: 2px solid rgba(0, 0, 0, 1);
      }
    }

    label {
      color: black;
    }
  }
}

#firstName {
  display: none;
}

.success-box {
  border: 3px solid palette(green, success);
  padding: 30px 20px;
  background-image: url('/dist/img/icons/check.svg');
  background-repeat: no-repeat;
  background-size: 35px;
  background-position: right 20px top 20px;
  margin-bottom: 30px;

  .h4 {
    margin-bottom: 10px;
    padding-right: 40px;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &--mini {
    border: 2px solid palette(green, success);
    padding: 15px 20px;
    background-position: right 20px top 12px;

     p {
       font-weight: bold;
     }
  }
}

.checkbox {
  display: flex;


  label {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    //font-size: 1.125rem;
    line-height: 1.8;
    padding-left: 30px;

    &::before, &::after {
      position: absolute;
      content: "";
      display: inline-block;
    }

    &::before {
      height: 16px;
      width: 16px;
      border: 2px solid palette(grey, dark);
      left: 0;
      background-color: transparent;
      top: 3px;
    }

    &::after {
      color: palette(blue, light);
      height: 5px;
      width: 10px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 8px;
    }
  }

  input[type="checkbox"] {

    + label::after {
      content: none;
    }

    &:checked + label {
      &:before {

      }

      &::after {
        content: "";
      }
    }

    &:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }

    &:disabled ~ label {
      &:before, &:after {
        opacity: .5
      }
    }
  }

  &.error {
    label::before, label::after {
      animation: shake .5s ease-in-out forwards;
      //transform: scale(1.2);
    }
  }


  &--white {
    label {
      line-height: 1.8;

      &.small {
        line-height: 1.5;

        &:before {
          top: 0;
        }

        &:after {
          top: 5px;
        }
      }

      &::before {
        border-color: white;
      }
    }
  }
}


.form-hold {
  position: relative;
  overflow: hidden;
}

.form-container {
  transition: all .5s ease .5s;
}

.form-complete {
  overflow: hidden;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(101%, -50%);
  transition: all .5s ease .5s;
}

.form-hold.complete {

  .form-container {
    opacity: 0;
    visibility: hidden;
  }

  .form-complete {
    transform: translate(0%, -50%);
  }
}

input[type=radio] {
  -webkit-appearance: radio;
}

.form--white {
  font-size: $p-size;
  color: palette(blue);

  label {
    font-size: 1.6rem;
    margin-bottom: 10px;
    font-weight: 500 !important;
    color: white;
  }

  select, input, textarea {
    border: 2px solid white;
    font-weight: 400;
    color: white;
    font-size: 1.6rem;
    padding: 7px 10px;
    background-color: rgba(255, 255, 255, .2);

    &:focus {
      border-color: #1397BB;
    }
  }

  select {
    padding: 7px 30px 7px 10px;
    background-position: right 5px center;
  }

  .checkbox label::before {
    border-color:white;
  }

  .checkbox input[type="checkbox"]:checked + label::before {
    background-color: white;
  }
}

.form--dark {
  font-size: $p-size;
  color: palette(blue);

  label {
    font-size: 1.6rem;
    margin-bottom: 10px;
    font-weight: 500 !important;
    color: palette(brand, purple);
  }

  select, input, textarea {
    border: 2px solid #656565;
    font-weight: 400;
    color: #282828;
    font-size: 1.6rem;
    padding: 7px 10px;
    background-color: rgba(255, 255, 255, .2);

    &:focus {
      border-color: #1397BB;
    }
  }

  select {
    padding: 7px 30px 7px 10px;
    background-position: right 5px center;
  }
}

.freeform-form-has-errors {
  background-color: palette(red, error);
  color: white !important;
  padding: 5px;
  margin-bottom: 15px;
}

.freeform-row .freeform-column .freeform-input[type=radio] {
  margin-bottom: 0;
}

.freeform-row .freeform-column .freeform-input[type=checkbox] {
  margin-bottom: 5px;
}

////

label {
  display: block;
  font-size: $p-size;
  color: palette(brand, purple);
  font-weight: bold;
  margin-bottom: 10px;
}

.form--inline {
  position: relative;
  width: 100%;

  input {
    padding-right: 50px;
  }

  button {
    position: absolute;
    height: 100%;
    top: 0;
    right: 15px;
    border: none;
    background-color: transparent;
  }

  &-email {
    input {
      color: white;
      padding-right: 150px;
      border-color: white;
      background-color: rgba(255, 255, 255, .1);


      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: white;
      }
    }

    button {
      background-color: palette(brand, blue);
      right:0;
      border: solid 2px white;
      border-radius: 30px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.form-rating-field-wrapper {
  > label {
    font-size: 500% !important;
  }
}

.freeform-row .freeform-column .freeform-instructions {
  margin: 0 0 5px;
  font-size: 1.5rem !important;
  color: palette(grey, dark) !important;
  font-weight: bold;
}