.accordion {

  .accord__item {
    cursor: pointer;
    margin-bottom: 25px;
    background: #FFFFFF;
    box-shadow: 0 5px 20px 0 #F6F6F6;
    font-size: 24px;
    color: #221C35;
    line-height: 34px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &--playbook {
      border-left: none;
      border-radius: 15px;

      .accord__target {
        font-size: 2rem;
        padding: 20px;
        padding-left: 75px;
        padding-right: 65px;

        .count {
          position: absolute;
          top: 10px;
          left: 10px;
          bottom: 10px;
          width: 50px;
          text-align: center;
          padding-top: 10px;
          background: #E4E4F6;
          border-radius: 7px;
        }

        .toggle {
          width: 25px;
          height: 25px;

          span {
            &, svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .accord__content {
        position: relative;

        .inner {
          padding-left: 75px;
        }
      }

      .i {
        position: absolute;
        top: 10px;
        left: 10px;
        bottom: 10px;
        width: 50px;
        text-align: center;
        padding-top: 10px;
        background: #E4E4F6;
        border-radius: 7px;
      }
    }

    &.is-open {
      .hide {
        opacity: 0;
      }

      .accord__content {
        max-height: 3000px;
      }
    }
  }

  .hide {
    transition: opacity .3s;
  }

  .accord__target {
    position: relative;
    padding: 30px;
    font-size: 2.6rem;
    font-weight: bold;
    padding-right: 100px;


    .rating {
      font-size: 0;

      span {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        margin-right: 5px;
      }

      li {
        font-size: 0;
        padding: 0 !important;
      }

      .dot {
        height: 17px;
        width: 17px;
        border-radius: 50%;
        background: #DEDEDE;

        &--red {
          background: #FF0000;
        }

        &--amber {
          background: #FF7900;
        }

        &--green {
          background: #22A254;
        }
      }
    }

    .toggle {
      position: absolute;
      background-color: palette(blue);
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      .icon, .icon > svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .accord__content {
    max-height: 0;
    overflow: hidden;
    transition: all .3s;

    .inner {
      padding: 10px 30px 30px;
    }
  }
}

@include breakpoint(small) {
  .accordion {
    .accord__content {
      padding-right: 100px;
    }

    .accord__target {
      .rating {
        position: absolute;
        right: 80px;
        top: 18px;
      }
    }

    .accord__item {
      &--playbook {
        .accord__target {
          padding-right: 240px;
        }
      }
    }
  }
}

.accordion-partners {

  .accord__item {
    cursor: pointer;
    margin-bottom: 25px;
    background: #FFFFFF;
    box-shadow: 0 5px 20px 0 #F6F6F6;
    font-size: 24px;
    color: #221C35;
    line-height: 34px;
    border-radius: 15px;

    &__type {
      padding: 10px 18px;
      text-align: center;
      background: #e4e4f6;
      font-weight: bold;
      border-radius: 8px;
      margin-right: 16px;
      font-size: 14px;

      @include breakpoint(small) {
        margin-right: 22px;
        padding: 18px 26px;
        font-size: 18px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &.is-open {
      .hide {
        opacity: 0;
      }

      .accord__content {
        max-height: 3000px;
      }
    }
  }

  .hide {
    transition: opacity .3s;
  }

  .accord__target {
    position: relative;
    padding: 16px;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.75;
    padding-right: 65px;
    display: flex;

    @include breakpoint(480px) {
      align-items: center;
    }

    @include breakpoint(small) {
      font-size: 2.2rem;
    }

    @include breakpoint(medium) {
      padding-right: 100px;
      font-size: 2.6rem;
    }

    .toggle {
      position: absolute;
      background-color: palette(blue);
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      @include breakpoint(medium) {
        right: 30px;
        width: 36px;
        height: 36px;
      }

      .icon, .icon > svg {
        width: 16px;
        height: 16px;

        @include breakpoint(medium) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .accord__content {
    max-height: 0;
    overflow: hidden;
    transition: all .3s;

    .inner {
      padding: 10px 16px 16px;
      display: flex;
    }
  }
}
