//=====================================
// #HEADINGS
//=====================================
$h1-size: 5.8rem;
$h2-size: 4.2rem;
$h3-size: 2.8rem;
$h3-size-fix: 26px;
$h4-size: 2.4rem;
$p-size: 1.6rem;
$p-size--small: 1.4rem;
$p-size--big: 2rem;

$heading-font: 'Montserrat', sans-serif;
$body-font: 'Montserrat', sans-serif;
