.event-slider__hold {

  .controls {
    text-align: right;
    padding-bottom: 15px;
  }

  .arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;

    .icon {
      &,
      & > svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.slider-side {
  border-top: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;

  &--private {
    .controls path {
      fill: palette(brand, purple);
    }
  }

  &--no-border {
    border: none;

    .slider__hold {
      border: none !important;
    }
  }

  &--preview {
    border: none;

    .text {
      display: block;
    }

    .inner {
      padding: 20px;
      margin: -50px 30px 20px 5px;
      position: relative;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .is-selected {
    z-index: 2;
  }

  .sidebar {
    display: flex;
    justify-content: space-between;
    background: white;
  }

  .controls {
    padding-top: 15px;
  }

  .partner-logo {
    max-width: 220px;
  }

  .partners {
    margin-top: 35px;
    margin-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid white;

    img {
      padding: 10px;
    }
  }

  .images {
    display: none;
  }

  .framework-heading {
    position: relative;
    padding-left: 80px;
    min-height: 60px;
    margin-bottom: 20px;

    img {
      position: absolute;
      width: 60px;
      top: 0;
      left: 0;
    }
  }

  .value {
    margin-bottom: 20px;
    margin-left: gutter(-1.5);

    p {
      display: inline-block;
      padding: 10px gutter(1.5);
      background-color: palette(grey, dark);
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  .arrow {
    background-color: transparent;
    border: none;

    .icon {
      &,
      & > svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .images {
    font-size: 0;
  }

  .logo {
    max-width: 150px;
  }

  .counter {
    padding-top: 20px;
    font-size: 2.2rem;
  }

  &--grey {
    background-color: palette(grey, dark);
    color: white;
    border: none;

    .sidebar {
      padding-bottom: 15px;
    }

    .side-slider {
      margin: 0 gutter(-1.5);
      width: auto;
    }

    .content {
      padding: gutter(1.5);

      .logo {
        max-width: 200px;
      }
    }

    .images {
      overflow: hidden;
      position: relative;
      max-width: 300px;
      margin: 0 auto;
      margin-top: -3vh;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 25%;
        clip-path: polygon(30% 0%, 100% 0, 100% 100%, 0% 100%);
      }
    }
  }
}

.slider-support__triggers {
  display: flex;
  flex-wrap: wrap;

  .support__trig {
    background-color: transparent;
    border: 0;
    flex-basis: 25%;
    transition: color .3s;
    text-align: left;
    padding: 0 20px 10px 0;
    cursor: pointer;
    outline: none;

    .slide__title, .orb {
      display: none;
    }

    .count__hold {
      position: relative;
      padding-top: 15px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 5px;
        width: 100%;
        background-color: #B5B5B5;
        transition: background-color .3s, height .3s;
      }
    }

    .count {
      font-weight: 400;
      font-size: $p-size;
    }

    &--active {
      color: palette(brand, blue);

      .count__hold {

        &::before {
          background-color: palette(brand, blue);
          height: 10px;
        }
      }
    }
  }
}

.little-slider.flickity-enabled .slider-item {
  display: flex;
  align-items: center;
  min-height: 100%;

  .img--responsive {
    width: 100%; //IE 11
  }
}

.slider-service__triggers {
  display: flex;
  align-items: flex-end;

  .service__trig {
    cursor: pointer;
    position: relative;
    flex: 1;
    padding: 20px 10px 30px;
    opacity: .4;
    transition: opacity .3s;

    &:hover {
      opacity: .7;
    }

    &::before, &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity .3s;
    }

    &::before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      bottom: 10px; //*1
    }

    &::after {
      width: 200vw;
      height: 10px; //*1
      bottom: 0;
    }

    &--active {
      opacity: 1 !important;

      &::before, &::after {
        opacity: 1;
      }
    }


    img {
      max-height: 80px;
      text-align: center;
    }
  }
}

.slider-services {
}

.step-slider--private {

  h3, .h3 {
    color: palette(brand, private);
  }
}

.slider-support__hold--private {

  &::before {
    border-color: palette(brand, private) !important;
  }

  .slider-support__triggers {

    .support__trig {
      .orb::before, &--active .orb::before {
        background-color: palette(brand, private) !important;
      }

      .orb::after, &--active .orb::after {
        border-color: palette(brand, private) !important;
      }

      &--active, &--active .count, &--active .slide__title {
        color: palette(brand, private) !important;
      }
    }
  }
}

.img-grid__slider-hold {
  .slider-item {
    width: 100%;
  }

  .img-grid__heading {
    h2 {
      display: inline-block;
    }
  }

  .controls {
    text-align: right;
    float: right;
    padding-left: 15px;
  }

  .is-selected {
    z-index: 2;
  }

  .arrow {
    background-color: transparent;
    border: none;
    padding: 0 8px;
  }

  .arrow .icon {
    &, svg {
      width: 35px;
      height: 35px;
    }
  }
}

.service__slider {
  opacity: 0;
  transition: opacity .3s;

  &--page {
    .slide-item {
      background: white;
    }
  }

  &.flickity-enabled {
    opacity: 1;
  }

  .slider-item {
    width: 100%;
  }

  .is-selected {
    z-index: 2;
  }
}

.service__slider-triggers {

  a {
    margin-right: 30px;
    transition: all .3s;


    &::before, &::after {
      width: 60px !important;
    }

    &:hover {
      margin-right: 20px;
    }

    &.btn--active {
      margin-right: 0;
    }
  }
}


@include breakpoint-max(small) {

  .slider-side .slide {
    position: relative;
    //padding-top: 59%;
    //
    //img {
    //  position: absolute;
    //  left: 0;
    //  top: 0;
    //  width: 100%;
    //}
  }


  .service__slider-triggers a.btn--active::after {
    transform: rotate(90deg) translateX(2px);
  }
}

@include breakpoint(small) {

  .slider-side {
    .sidebar {
      border-top: 1px solid #D0D0D0;
      border-left: 1px solid #D0D0D0;
      margin-top: 6vh;
      padding-bottom: 0;
    }

    .slider__hold {
      border-left: 1px solid #D0D0D0;
    }

    .content {
      padding: 0 30px;
    }

    .images {
      display: block;
    }

    .sidebar {
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
    }

    .counter {
      padding-top: 0;
      transform: rotate(-90deg);
      padding-right: 200px;
      white-space: nowrap;
      font-size: 3.5rem;
    }

    .value {
      margin-left: -31px;

      p {
        padding: 10px 30px;
      }
    }

    &--preview {
      border: none;
      box-shadow: 0 17px 28px -22px #221C35;

      .slide {
        background-color: palette(brand, blue);
        padding: 30px;
        min-height: 500px;
        display: flex;
        align-items: center;

        .img--responsive {
          width: 100%;
          height: 100%;
          object-fit: cover;
          @include cover();
          font-family: 'object-fit: cover;'; // Polyfill for object-fit
        }
      }

      .slider__hold {
        border: none;
      }

      .inner {
        max-width: 570px;
        padding: 25px;
        margin: 0;
        box-shadow: none;
      }

      .sidebar {
        border-right: 1px solid #D0D0D0;
        border-bottom: 1px solid #D0D0D0;
        margin: 0;
      }
    }

    &--grey {
      .sidebar {
        margin: 0;
        border-top: 0;
        border-right: 1px solid #D0D0D0;
        padding-top: 6vh;
      }

      .slider__hold {
        border-left: none;
      }

      .side-slider {
        margin: 0;

        .slide {
          min-height: 100%;
          display: flex;

          > * {
            min-height: 100%;
          }
        }
      }

      .content {
        min-height: 100%;
        padding: 10vh 100px 10vh 30px;
      }

      .images {
        max-width: 250px;
        margin: 0 auto;
        margin-left: -90px;
        padding-right: 30px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        &::before {
          right: 30px;
          width: 20%;
        }
      }
    }
  }

  .slider-service__triggers {

    .service__trig {
      padding: 30px 30px 40px;
    }
  }
}

@include breakpoint(medium) {

  .service__slider-triggers {

    li {
      margin-bottom: 10px;
    }

    a {
      margin-right: 50px;
      transition: all .3s;


      &::before, &::after {
        width: 60px !important;
      }

      &:hover {
        margin-right: 35px;
      }

      &.btn--active {
        margin-right: 0;
      }
    }
  }

  .slider-side {

    .sidebar {
      padding-bottom: 40px;
    }

    .content {
      padding: 0 60px;
    }

    .arrow {
      padding: 0 12px;

      .icon {
        &,
        & > svg {
          width: 35px;
          height: 35px;
        }
      }
    }

    &--grey {

      .content {
        padding: 10vh 100px 10vh 30px;
      }

      .images {
        padding-right: 50px;
        max-width: 400px;

        &::before {
          right: 50px;
        }
      }
    }


  }

  .slider-support__hold {
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      border-bottom: 3px solid palette(blue);
      background-color: white;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 50px;
    }
  }

  .slider-support__triggers {

    .support__trig {
      text-align: center;
      flex: 1;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 10px;
      font-size: 0;

      .slide__title, .orb {
        display: block;
      }

      .count {
        color: palette(brand, purple);
        padding-bottom: 10px;
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }

      .orb {
        position: relative;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin: 0 auto;

        &::after, &::before {
          content: "";
          border-radius: 50%;
        }

        &::after {
          display: block;
          width: 43px;
          height: 43px;
          border: 2px solid palette(blue);
          transition: opacity .3s, transform .3s;
        }

        &::before {
          width: 10px;
          height: 10px;
          background-color: palette(blue);
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: left top;
          transform: translate(-50%, -50%);
          transition: transform .3s;
        }
      }

      .slide__title {
        color: palette(brand, purple);
        padding-top: 8px;
        font-size: $p-size--small;
      }

      .count__hold {
        padding-top: 29px;
        padding-bottom: 13px;

        &::before {
          content: none;
          top: initial;
          bottom: 0;
          height: 8px;
          background-color: transparent;
        }
      }


      &--active {
        font-weight: bold;

        .count {
          font-weight: bold;
        }

        .count__hold {
          &::before {
            background-color: palette(brand, blue);
          }
        }

        .orb::before {
          transform: scale(2) translate(-50%, -50%);
        }

        .orb::after {
          transform: scale(0);
          opacity: 0.5;
        }

        .count, .slide__title {
          color: palette(brand, blue);
        }
      }
    }
  }
}

@include breakpoint(large) {

  .slider-side {


    &--grey {
      .images {
        max-width: 450px;
      }
    }
  }


  .slider-support__hold {

    &::before {
      height: 49px;
    }
  }
}

.team__slider {
  opacity: 0;
  transition: opacity .3s;

  &.flickity-enabled {
    opacity: 1;
  }
}