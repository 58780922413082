//=====================================
// #STRUTURE
//=====================================
.wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-left: gutter(1.5);
  padding-right: gutter(1.5);

  @include breakpoint(small) {
    width: 720px;
  }

  @include breakpoint(medium) {
    width: 970px;
  }

  @include breakpoint(large) {
    width: 1200px;
    max-width: 100%;
  }

  //@include breakpoint(large) {
  //  width: 1260px;
  //}

  @extend %clearfix;
}

.wrapper--aux {

  @include breakpoint(large) {
    width: 1200px;
  }
}

.wrapper--tight {
  @include breakpoint(large) {
    width: 960px;
  }
}


.wrapper--x-tight {
  @include breakpoint(small) {
    width: 700px;
  }
  @include breakpoint(medium) {
    width: 740px;
  }
}

.wrapper--full {
  @include breakpoint(small) {
    width: 100%;
  }
}

.wrapper--color-block {

  @include breakpoint(medium) {
    width: 1260px;
    margin-right: auto;
    margin-left: auto;
  }

}

.wrapper--pn {
  position: initial !important;
}