.pills {
  margin-bottom: 25px;

  li {
    padding-bottom: 0;
  }
}

.pill {
  position: relative;
  display: inline-block;
  background-color: rgba(0, 154, 191, .2);
  color: palette(brand, purple);
  border-radius: 15px;
  padding: 2px 15px 2px 35px;

  &::before {
    content: "";
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: palette(brand, purple);
  }


  &--blue {
    background-color: rgba(0, 156, 189, .15);
    font-weight: bold;

    &::before {
      background-color: palette(brand, blue);
    }
  }


  &--white {
    background-color: rgba(0, 156, 189, .3);
    font-weight: bold;
    color: white;

    &::before {
      background-color: palette(brand, blue);
    }
  }
}

@include breakpoint(small) {
}

@include breakpoint(medium) {
}

@include breakpoint(large) {
}