//=====================================
// #HEADINGS
//=====================================
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
  font-family: $heading-font;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 25px;
}

h1, .h1 {
  font-size: 4rem;
  @include breakpoint(small) {
    font-size: $h1-size;
  }
}

h2, .h2 {
  font-size: 4rem;
  @include breakpoint(small) {
    font-size: $h2-size;
  }

  &--small {
    font-size: 3.8rem !important;
  }
}

h3, .h3 {
  font-size: $h3-size;
}

h4, .h4 {
  font-size: $h4-size;
}

h5, .h5 {
  font-size: 2.2rem;
}

.tagline {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.3;
  text-transform: uppercase;
  padding-bottom: 0;
  margin-bottom: 20px;
  letter-spacing: 2px;

  &--ghost {
    opacity: 0;
  }
}

.nudge {
  margin-right: 10px;
}

.nudge--md {
  margin-right: 20px;
}

.nudge--left-sm {
  margin-left: 10px;
}

.nudge--left-md {
  margin-left: 20px;
}

.flat, .short {
  margin-bottom: 10px;
}

.flat--none {
  margin-bottom: 0;

  @include breakpoint(small) {
    &--sm {
      margin-bottom: 0;
    }
  }
}

p, .p {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 20px;
  font-weight: 400;
}

.small > p,
.small > .p,
.small {
  font-size: $p-size--small;
}

.big > p,
.big > .p,
.big {
  font-size: $p-size--big;
}


.large > p,
.large > .p,
.large {
  font-size: 2.6rem;
}

a.underline {
  text-decoration: underline;
}

.normal {
  font-weight: 400;
}

.medium, .medium > * {
  font-weight: 500;
}

.bold, .bold > * {
  font-weight: bold;
}

.opacity--50 {
  opacity: 0.5;
}

.link, .link:link {
  text-decoration: underline;
  font-weight: bold;
}


.link--arrow {
  position: relative;
  font-weight: bold;
  font-size: $p-size;
  padding-right: 30px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 17px;
    height: 12px;
    background-image: url("/dist/img/icons/arrow-right-blue.svg");
    background-size: 17px 12px;
    transition: transform .3s;
  }

  &:hover {
    &::before {
      transform: translateY(-50%) translateX(10px);
    }
  }

  &-white {
    &::before {
      background-image: url("/dist/img/icons/arrow-right-white.svg");
    }
  }
}

a, .link--fake {
  cursor: pointer;
  text-decoration: none;
  color: palette(blue);
  font-weight: inherit;
  transition: color .3s;

  &:hover {

  }
}

.link--white {
  color: white;
}

.link--blue-dark {
  color: palette(brand, purple);
}

.link--white-hover {
  &:hover, &:focus {
    color: white;

    svg path {
      fill: white;
    }
  }
}

.link--icon {
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color .3s;

  .text {
    text-decoration: underline;
  }

  .icon, .icon > svg {
    width: 20px;
    height: 20px;
  }

  .icon {
    margin-left: 10px;
  }

  svg path {
    transition: fill .3s;
  }

  &:hover svg path {

  }
}

.link--delete {
  color: #ffffff;
  background: palette(red, light);
  padding: 0 10px;
  border-radius: 4px;
  padding: 4px 6px;
  cursor: pointer;

  &:hover {
    background: darken(palette(red, light), 5%);
  }
}

button.link--delete {
  border: none;
}

.ul--row {
  display: inline-block;

  li {
    display: inline-block;
  }
}

.ul--ticks {
  &, ul {
    li {
      width: 100% !important;
      background-image: url("/dist/img/icons/blue-tick-box.svg");
      background-repeat: no-repeat;
      background-size: 18px;
      background-position: top 5px left;
      padding-left: 25px !important;

      &::before {
        content: none !important;
      }
    }
  }

  &--private {
    &, ul {
      li {
        background-image: url("/dist/img/icons/purple-tick-box.svg");
      }
    }
  }
}


li {
  font-size: 16px;
  line-height: 1.8;
  color: palette(brand, purple);
  padding-bottom: 15px;
}

ul.list {
  li {
    padding-bottom: 15px;
  }
}

ul.ul--row {
  li {
    display: inline-block;
  }
}

.body {


  .btn {
    white-space: break-spaces;
  }


  figure img {
    width: 100%;
    max-width: 100%;
  }

  .logo-body {
    max-width: 150px;
    margin: 35px 0;
  }

  .img {
    margin: 35px 0;
  }

  .youtube {
    margin: 35px 0;

    iframe {
      width: 100%;
      height: 300px;

      @include breakpoint(small) {
        height: 400px;
      }
    }
  }

  ol {
    list-style: auto !important;
    margin-left: 15px;
  }


  ul {
    list-style: none;
    margin-bottom: 10px;

    li {
      position: relative;
      padding-left: 15px;
      line-height: 1.8;
      margin-left: 0;

      &::before {
        content: "\2022";
        color: #1397BB;
        display: inline-block;
        width: 1em;
        margin-left: -15px;
        margin-top: -8px;
        line-height: 1;
        position: absolute;
        top: 0;
        font-size: 36px;
      }
    }
  }

  .quote-wrap {
    .quote > p {
      font-weight: bold;
      line-height: 1.3;
      font-size: 1.8rem;

      &::before {
        content: "";
        display: inline-block;
        width: 50px;
        height: 5px;
        margin-bottom: 5px;
        margin-right: 15px;
        background-color: palette(brand, purple);
      }

      @include breakpoint(small) {
        &::before {
          width: 80px;
        }
      }
      @include breakpoint(medium) {
        &::before {
          width: 110px;
        }
      }
    }
  }
}

//=====================================
// #COLOURS/LINKS
//=====================================
.color--white {
  color: white;

  > * {
    color: white;
  }
}

.color--red, .color-red > * {
  color: palette(red, error);
}

.color--green, .color--green > * {
  color: palette(green, success);
}

.color--blue {
  color: palette(blue);

  > * {
    color: palette(blue);
  }
}

.color--blue-dark {
  color: palette(brand, insight);

  > * {
    color: palette(brand, insight);
  }
}

.color--black {
  color: #333;
}

.color--grey {
  color: palette(grey, base);

  > * {
    color: palette(grey, base);
  }
}


.turtl {
  max-width: 550px;
  margin: 35px auto;
}


.color--purple {
  color: palette(brand, blog);

  > * {
    color: palette(brand, blog);
  }
}

.color--purple-dark {
  color: #221C34;

  > * {
    color: #221C34;
  }
}

.scape--blue {
  font-style: normal;
  color: #1397BB;
}

.callout {
  background-color: palette(grey, light);
  padding: 20px;
  margin-bottom: 25px;

  p:last-of-type {
    margin-bottom: 0;
  }
}

.nav__item {
  margin-right: 10px;
  color: palette(grey);
  cursor: pointer;
  display: block;

  @include breakpoint(medium) {
    display: inline-block;
  }

  &.btn {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-right: 0;
  }
}


.link--active {
  color: palette(blue);
  font-weight: bold;
}

.back {
  span {
    margin-right: 20px;
  }

  &:hover {
    color: palette(brand, blue);
  }
}

.bar {
  border-bottom: 1px solid #D0D0D0;
}


// OTHER

@include breakpoint(small) {
  .card___hold {
    overflow: hidden;
    padding-left: 25px;

    .card {

      box-shadow: -7px 0px 12px -8px #bfbfbf;
    }

    &--right {
      padding-right: 25px;
    }
  }

  .card {
    padding: 20px;
    box-shadow: -7px 0px 12px -8px #bfbfbf;
  }

  .card--right {
    padding-right: 40px;
  }
}

@include breakpoint(medium) {
  .card {
    padding: 40px 0 40px 40px;
  }
}

.download {
  position: relative;
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 30px;
  color: palette(brand, purple);

  p {
    margin: 0;
    line-height: 1.5;
    display: inline-block;
    border-bottom: 2px solid transparent;
    transition: border .3s;
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("/dist/img/icons/arrow-down-blue.svg");
    background-repeat: no-repeat;
    background-size: 22px;
    width: 22px;
    height: 37px;
    background-position: right center;
    transition: top .3s;
  }

  &:hover {

    p {
      border-color: palette(brand, blue);
    }

    &::before {
      top: 60%;
    }
  }
}

.downloads {
  padding-top: 10px;

  > p {
    border-bottom: 2px solid palette(brand, purple);
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  .download__item {
    position: relative;
    display: block;
    padding: 15px 30px 15px 0;
    border-bottom: 2px solid palette(brand, purple);
    color: palette(brand, purple);

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("/dist/img/icons/arrow-down-blue.svg");
      background-repeat: no-repeat;
      background-size: 25px;
      width: 25px;
      height: 40px;
      background-position: right center;
      transition: top .3s;
    }

    p:first-of-type {
      margin-bottom: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      &::before {
        top: 60%;
      }
    }
  }
}

.quote-single {
  padding-top: 50px;
  background-image: url("/dist/img/icons/quote-mark-blue.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 40px;

  p:first-of-type {
    font-size: 4rem;
    line-height: 1.3;
  }

  @include breakpoint(medium) {
    &--pull-up {
      transform: translateY(-100px);
    }
  }
}

.f0 {
  font-size: 0;
}

.nav__social {
  display: flex;
  justify-content: space-between;
}

.piping--public {
  border-color: palette(brand, blue) !important;
}

.quote-piping--public > p::before {
  background-color: palette(brand, blue) !important;
}

.piping--private {
  border-color: #5F2167 !important;
}

.quote-piping--private > p::before {
  background-color: #5F2167 !important;
}


.youtube--large {
  iframe {
    width: 100%;
    height: 300px;
    @include breakpoint(small) {
      height: 400px;
    }
    @include breakpoint(medium) {
      height: 500px;
    }
    @include breakpoint(large) {
      height: 520px;
    }
  }
}

.error-block {
  padding: 20px;
  border: 2px solid palette(red, error);
}

.body {
  table {
    margin-bottom: 35px;
  }

  .matterport {
    margin-bottom: 35px;

    iframe {
      width: 100%;
    }
  }
}

table {
  font-size: 16px;
}