// ==========================================================================
// Modal
// ==========================================================================

/* Parent element can be any width and height */
.modal {
  position: fixed;
  z-index: 99999;
  width: 100%; /* can be any width */
  height: 100%;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  overflow: scroll;
  padding-top: 20px;

  @include breakpoint(small) {
    align-items: center;
    padding-top: 0px;
  }

  &:not(.is-visible){
    display: none;
  }
}

.modal:before { /* pseudo element to force vertical centering of child element */
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal:after { /* pseudo element to create overlay */
  background-color: palette(brand, purple);
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;

  // To account for any overflow scroll (so background doesn't cut off)
  @include breakpoint-max(small) {
    height: 185%;
  }

  top: 0;
  left: 0;
  z-index: 10;
}

/* Centered child element can be any width and height */
.modal__inner {
  position: relative; /* position element to participate in z-indexing */
  z-index: 20; /* higher z-index than overlay */
  display: inline-block;
  vertical-align: middle;
  width: 90%; /* can be any width */
  max-width: 1100px;

  @include breakpoint(small) {
    width: 70%; /* can be any width */
  }
}

.modal__content {
  position: relative;
  background: #FFFFFF;
  padding: 12px 12px 32px 12px;
  border-radius: 15px;

  .modal__dismiss {
    z-index: 11;
  }

  .modal__title {
    font-size: 2.6rem;
    font-weight: bold;
    text-align: center;
  }

  .modal__text {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

/* Video container to maintain Youtube 16:9 aspect ratio */
.modal__video {
  position: relative;
  padding-top: 25px;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  margin-bottom: 20px;
}

/* Make Youtube IFRAME responsive */
.modal__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__dismiss {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  transform: translate(25%, -25%);
  background: palette(brand, blue);
  border-radius: 50%;
  cursor: pointer;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 3px;
    background: #FFFFFF;
    border-radius: 4px;

    &:nth-of-type(1) {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:nth-of-type(2) {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}