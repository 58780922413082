$palettes: (
        black: (
                base: #000000
        ),
        blue: (
                x-light: #3ABDE1,
                light: #56CBFF,
                base: #1397BB,
                dark: #0e728d,
                x-dark: #0c3992
        ),
        grey: (
                x-light: #F3F3F3,
                light: #E1E1E1,
                mid-light: #B6B6B6,
                base: #9C9C9C,
                mid-dark: #6C6C6C,
                dark: #333333, // Medium grey, body copy
                x-dark: #282828,
                nc: #65666a,
                slight-dark: #4E4E4E
        ),
        green: (
                base: #007200,
                light: #00af00,
                success: #00857D
        ),
        red: (
                error: #EE3D52,
                light: #EA5E5E
        ),
        pink: (
                base: #d90573
        ),
        brand: (
                blue: #009CBD,
                purple: #221C35,
                blog: #B459BF,
                insight: #054798,
                lungfish-black: #282828,
                arc-blue:#04A8AE,
                private:#5F2167
        )
) !global;

// ==========================================================================
// COLOR PALETTE FUNCTION
// ==========================================================================
// Example:
//
// a {
//     color: palette(yellow, light);
// }
//

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}