//=====================================
// #CTA
//=====================================
.cta--split {
  .cta__half {

    .inner {
      position: relative;
      z-index: 2;
      padding: 40px 15px;

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .logo {
      max-width: 180px;
    }

    .inner--central {
      margin: 0 auto;

      .link {
        display: inline-flex;
      }
    }
  }

  p a {
    color: white;
    text-decoration: underline;
  }

  .cta__input-line {
    display: flex;

    .btn {
      width: 130px;
      background-color: transparent;
      color: white;
      border: 1px solid white;
      margin: 0;
      padding-left: 5px;
      padding-right: 5px;

      &:hover {
        background-color: white;
        color: #0e728d;
      }
    }

    .input {
      flex: 1;
      color: white;
      border: 1px solid white;
      margin: 0;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: white;
      }
    }
  }
}

.cta-strip {
  border-radius: 10px;
  position: relative;
  padding-right: 80px;



  .cta__image {
    max-width: 400px;
    margin: 0 auto;
  }

  .mask {
    overflow: hidden;
  }

  .inner {
    padding: 20px;

    .text {
      ul {
        margin-top: 25px;

        li {
          color: inherit;
          font-weight: 400;
          position: relative;
          padding-left: 15px;
          padding-bottom: 20px;
          line-height: 1.3;

          &::before {
            content: "\2022";
            position: absolute;
            left: 0;
            top: 0 !important;
            padding: 0;
          }
        }
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  &--no-right {
    padding-right: 20px;
  }

  ul li {
    vertical-align: top;
    margin-bottom: 0;
  }

  a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }

  .strip {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    padding: 20px 0;
    transition: width .3s;

    &::before {
      content: "";
      @include cover();
      transform: rotate(3deg);
      background-color: palette(brand, blue);
      border-left: 3px solid white;
      width: 200%;
      height: 200%;
    }

    &:hover {
      width: 80px;
    }
  }

  .strip__inner {
    text-align: center;
    font-size: 1.8rem;
    line-height: 22px;
    position: absolute;
    bottom: 20px;
    width: 100%;
  }

  .icon {
    margin-top: 10px;
  }


  .cta__image {
    font-size: 0;
    padding: 0 20px;
  }

}

.cta-support {
  border-radius: 10px;
  background: #ECECEC;
  box-shadow: 0 0 24px -11px #221C34;
  position: relative;
  overflow: hidden;
  display: block;
  padding: 30px 60px 0 0;
  text-align: center;
  transition: transform .3s, box-shadow .3s;

  .people {
    position: relative;
    opacity: .8;
    transition: opacity .3s;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(34, 28, 52, 1) 5%, rgba(34, 28, 52, 0) 60%);
    }
  }

  .group {
    font-size: 0;

    &:nth-child(3), &:nth-child(4) {
      display: none;
    }
  }

  .arrow {

    padding: 20px;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 65px;
    bottom: -1px;

    p {
      position: absolute;
      right: 100%;
      white-space: nowrap;
      bottom: 20px;
      margin: 0;
      margin-right: 20px;
    }

    &::before {
      content: "";
      @include cover();
      background-color: palette(brand, blue);
      border-left: 3px solid white;
      transform: rotate(3deg);
      height: 200%;
      width: 200%;
    }

    .icon {
      position: relative;
    }

  }

  &:hover {
    // box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
    .people {
      opacity: .5;
    }
  }
}

@include breakpoint(small) {


  .cta--split {
    display: flex;

    .cta__half {
      flex: 1;
      position: relative;

      .inner {
        padding: 60px 40px;
        max-width: 595px;
        margin: 0 auto;
      }

      .inner--central {
        margin: 0 auto;
      }


      &:first-of-type .inner--pad {
        padding: 60px 20px;
      }

      &:last-of-type .inner--pad {
        padding: 60px 20px;
      }
    }
  }

  .cta-support {
    padding: 40px 60px 0 0;

    .group {
      &:nth-child(3) {
        display: block;
      }
    }
  }
}

@include breakpoint(medium) {

  .cta-strip {
    align-items: flex-end;

    .g {
      align-items: flex-end;
    }


    .cta__content {
      vertical-align: top;
    }

    .cta__image {
      padding: 0 0 0 20px;
      vertical-align: bottom;

      img {
        max-height: 430px;
        width: 100%;
        margin-top: -100px;
        margin-left: auto;
      }
    }

    .text {

      ul li {
        width: 50%;
        display: inline-block;
      }
    }

    .inner {
      padding: 50px;
    }
  }

  .cta--split {
    .cta__half {
      padding: 60px 70px 60px 70px;

      .inner {
        padding: 100px 40px;
      }
    }


    &-single .cta__half .inner {
      padding: 100px 40px !important;
    }
  }

  .cta-support {


    .group {
      &:nth-child(4) {
        display: block;
      }
    }
  }
}