//=====================================
// #PAGINATION
//=====================================
.pagination-plain {
  .pagination__item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    margin-bottom: 0;
  }
}

.pagination {
  padding: 15px;
  font-size: 1.6rem;
  box-shadow: 0 17px 16px -18px palette(blue);

  .pagination__item {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    padding-top: 8px;


    &--pager {
      padding: 0;
      margin: 0 10px;
    }

    &--link {

    }

    &--active {
      background-color: palette(blue);
      color: white;
      border-radius: 50%;
      overflow: hidden;
    }

    &--inactive {
      opacity: .5;
    }
  }

  .pagination__item--pager {

  }

}