// ==========================================================================
// Header
// ==========================================================================
#header {
  background-color: #221C35;
  width: 100%;
  //max-height: 72px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  transition: background .3s;

  &.header--tran {
    background-color: transparent;

    ~ main .hero--builder, ~ main .hero--study {
      padding-top: 50px;
      padding-bottom: 50px;

      @include breakpoint(large) {
        padding-top: 72px;
        padding-bottom: 72px;
      }
    }
  }

  &.header--tran.header--untran {
    background-color: #4A4A4A;

    .email a {
      color: palette(blue);
    }
  }

  &.header--scrolled {
    background-color: palette(brand, purple);
  }

  .logo {
    position: relative;
    z-index: 998;
    padding: 10px;

  }

  .navigation--back {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    padding: 5px 10px;

    .text {
      font-size: 1.8rem;
      color: white;
      font-weight: bold;
    }

    .icon {
      &, svg {
        height: 46px;
        width: 46px;
      }
    }
  }

  .navigation {
    display: flex;
    align-content: center;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    padding: 5px 10px;

    .burger-menu {
      margin-left: 20px;
    }

    .desktop-contact {
      display: none;
    }

    .mobile-phone {
      .icon {
        &,
        & > svg {
          width: 25px;
          height: 25px;

          path {
            fill: white;
          }
        }
      }
    }
  }

  .side-nav {
    position: fixed;
    z-index: 997;
    background: #221C34;
    height: 100vh;
    top: 0;
    right: 0;
    width: 100%;
    padding-top: 55px;
    transform: translateX(100%);
    transition: transform .5s ease-in-out, border .3s;

    .side-nav_inside {
      height: 100%;
      overflow: auto;
      padding: 20px 0 150px;
    }

    .side-nav_support {
      display: none;
    }

    .side-nav_navigation {
      display: none;
    }

    .menu--breaker {
      margin-top: 35px;
    }

    .links--big {

      .icon {
        margin-right: 15px
      }

      ul {
        padding: 0;
        list-style: none;
        margin-left: 0;
      }

      li {
        margin-bottom: 10px;
        padding-bottom: 0;
      }

      a {
        text-decoration: underline;
        font-size: 1.6rem;
      }
    }

    .sub-nav__head {
      margin-right: -75px;
      margin-left: -35px;
      padding: 10px 75px 10px 35px;
      display: block;

      &.public {
        background-color: palette(brand, blue);
        color: palette(brand, purple);
      }

      &.private {
        background-color: #5F2167;
        color: white;
      }
    }

    ul.nav {
      overflow: visible;
      list-style: none;
      padding: 0;
      margin-left: 0;

      > li {
        padding: 0 20px;
        margin-bottom: 25px;

        &:last-of-type {
          margin-bottom: 0;
        }

        > a {
          cursor: pointer;
          position: relative;
          display: block;
          padding-bottom: 4px;
          line-height: 1.5;
          font-weight: bold;
          color: white;
          transition: color .3s;
        }

        .icon--arrow {
          &,
          & > svg {
            width: 25px;
            height: 25px;
            pointer-events: none;
          }

          &-push {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .sub-nav {
          padding: 75px 0 70px;
          margin: 0 -20px;
          background-color: #1B162B;
          width: 100%;
          height: 100vh;
          transform: translateX(100%);
          position: fixed;
          z-index: 2;
          top: 0;
          transition: transform .5s;

          > ul {
            padding: 0 20px;
            height: 100%;
            overflow: auto;
            list-style: none;
          }

          li {
            position: relative;
            margin-bottom: 25px;
            padding-right: 35px;

            &.preview__item {
              padding: 0;
              margin-top: 100px;

              .preview--block {
                margin-bottom: 10px;
              }
            }
          }

          a {
            cursor: pointer;
            line-height: 1.5;
            font-weight: bold;
            color: white;
          }


          .sub-nav__back {
            background-color: #221C34;
            display: block;
            margin-bottom: 25px;
            padding: 15px 20px;
            margin-left: -20px;
            margin-right: -20px;

            .icon {
              margin-right: 15px;
            }
          }

          &--frameworks {

            .tagline {
              font-size: 1.8rem;
              font-weight: 400;
              line-height: 1.3;
              text-transform: uppercase;
              padding-bottom: 0;
              margin-bottom: 20px;
              letter-spacing: 1px;
            }


            .framework--nav-item {
              position: relative;
              min-height: 45px;
              display: flex;
              align-items: center;

              .framework__image {
                width: 45px;
                padding: 2px;
                background: white;
                border-radius: 50%;
                font-size: 0;

                img {
                  width: 100%;
                }

              }

              .framework__label {
                flex: 1;
                padding-left: 15px;
                padding-right: 50px;
              }
            }
          }

          &--whatwedo {
            .icon--arrow-push {
              top: 100%;
              transform: translateY(-100%);
            }

            li {
              margin-bottom: 40px;
            }

            span {
              font-weight: 300;
            }

            .whatwedo__image {
              width: 125px;
              margin-bottom: 10px;
            }
          }
        }

        &.open {

          .sub-nav--contact {

            .links--big {
              li {
                padding-right: 0;
              }
            }
          }

          .sub-nav {
            transform: translateX(0);

            li {
              padding-bottom: 0;

              @include breakpoint-max(medium) {
                &:first-of-type {
                  padding-right: 0;
                }
              }
            }
          }

          > a {
            color: palette(blue);
            background-color: #1B162B;
          }
        }
      }
    }
  }


  @include breakpoint-max(medium) {
    .side-nav {
      .side-nav_support {
        overflow: auto;
        display: block;
        padding: 75px 0 0;
        margin: 0;
        background-color: #1B162B;
        width: 100%;
        height: 100vh;
        transform: translateX(100%);
        position: fixed;
        z-index: 2;
        top: 0;
        transition: transform .5s;

        a {
          color: white;
        }

        &.open {
          transform: translateX(0%);
        }

        .sub-nav__back {
          background-color: #221C34;
          color: white;
          display: block;
          margin-bottom: 25px;
          padding: 15px 20px;
          font-size: $p-size;
          cursor: pointer;
          line-height: 1.5;
          font-weight: bold;

          .icon {
            margin-right: 15px;

            &, svg {
              width: 25px;
              height: 25px;
              margin-right: 15px;
            }
          }
        }

        .support__item {
          padding: 0 20px;
        }
      }
    }
  }

  @include breakpoint(medium) {

    .navigation-burger {
      padding: 15px 12px;
    }

    .side-nav {
      ul.nav > li {

        > a {
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 35px;
          transition: background-color .3s;

          &:hover {
            background-color: #1B162B;
          }
        }

        .icon--arrow-push {
          right: 35px;
        }

        .sub-nav {
          .icon--arrow {
            &,
            & > svg {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }


    .logo {
      padding: 10px 15px;
    }

    .navigation {

      .mobile-phone {
        display: none;
      }

      .desktop-contact {
        display: block;
        text-align: right;
        padding-top: 2px;

        a {
          font-weight: bold;
          transition: color .3s;
        }

        .phone a {
          color: white;

          &:hover {
            color: palette(blue);
          }
        }
      }

      .burger-menu {
        margin-top: 10px;
      }
    }

    .side-nav {
      width: 900px;

      .side-nav_inside {
        padding: 0;
        overflow: auto;


        ul.nav > li .sub-nav .links--big {
          li {
            margin-bottom: 10px;
          }

          a {
            transition: opacity .3s;

            &:hover {
              opacity: 1;
            }
          }
        }

        .side-nav_navigation {
          padding-top: 60px;

          ul.nav {
            padding: 0;
            width: 50%;
            padding-bottom: 80px;

            > li {
              padding: 0;
              margin-bottom: 10px;

              .input--large-search {
                margin: 0 35px;
              }

              > a {
                cursor: pointer;
              }

              .sub-nav {
                padding: 70px 0 0;
                margin: 0;
                width: 450px;
                transform: translateX(200%);

                > ul {
                  padding: 50px 0 0;

                  > li {
                    padding: 0 75px 0 35px;
                    margin-bottom: 30px;

                    &:first-of-type {
                      display: none;
                    }

                    &.preview__item {
                      padding: 0 35px;
                    }
                  }
                }

                a {
                  display: block;

                  .icon--arrow {
                    transition: transform .3s;
                  }

                  &:hover .icon--arrow {
                    transform: translateX(15px) translateY(-50%);

                    path {
                      fill: palette(blue);
                    }
                  }
                }

                .icon--arrow-push {
                  right: 35px;
                }

                &--whatwedo a:hover .icon {
                  transform: translateX(15px) translateY(-100%);
                }
              }

              &.open .sub-nav {
                transform: translateX(100%);
              }
            }
          }
        }

        .side-nav_support {
          display: block;
          width: 50%;
          height: 100vh;
          background-color: #1B162B;
          padding: 115px 35px 80px;
          position: absolute;
          top: 0;
          right: 0;
          overflow: auto;

          .sub-nav__back {
            display: none;
          }


          a {
            color: white;
            transition: opacity .3s;
          }

          a:hover {
            opacity: 1;
          }

          .support__item--menu {

            ul {
              margin-left: 0;
              padding-top: 0;
            }

            li, .h3 {
              margin-bottom: 20px;
              padding: 0;
              list-style: none;
            }

            a {
              line-height: 1.5;
              color: white;

              .icon {
                display: none;
              }
            }
          }
        }
      }
    }
  }

}

.netzero {
  #header {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200%;
      background: linear-gradient(180deg, rgba(34, 28, 53, 0.6) 0%, rgba(34, 28, 53, 0) 100%);
    }
  }
}

.background__overlay {

  opacity: 0;
  transition: all 0.5s;

  &.is-active {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .3;
    background-color: #221C35;
  }
}

#header.is-open {

  @include breakpoint-max(medium) {
    .logo {
      background: #221C35;
    }
  }

  .side-nav {
    transform: translateX(0);

    .side-nav_inside .side-nav_navigation {
      display: block;
    }
  }
}