// ==========================================================================
// HTML & Body
// ==========================================================================
$base-line-height: 28;
$min-font: 17px;
$max-font: 21px;

html {
  height: 100%;
}

html, body {
  max-width: 100%;
  font-size: 55% !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100% !important;
  font-family: $body-font;
  font-style: normal;
  font-weight: 400;
  color: palette(brand, purple);
  background: white;

  @include breakpoint(large) {
    font-size: 62.5% !important;
    /* Normalise font size so that 1rem = 10px, 2rem = 20px etc. */
  }
}

body {
  position: relative;

  &.fix {
    overflow: hidden;
  }
}

main {
  position: relative;
  z-index: 1;
  @extend %clearfix;
  overflow: hidden;

  &.main--push {
    padding-top: 57px;

    @include breakpoint(large) {
      padding-top: 58px;
    }
  }

  &.main--push-partners {

    > *:first-of-type {
      padding-top: 58px;
    }


    @include breakpoint(small) {
      > *:first-of-type {
        padding-top: 91px;
      }
    }
  }
}

.bkg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9996;
  will-change: opacity;
}

::-moz-selection {
  background-color: palette(blue);
  color: white;
}

::selection {
  background-color: palette(blue);
  color: white;
}

.wrapper--pn {
  position: static !important;

  .text {
    position: relative;
  }
}