.toolbar {
  position: relative;
  z-index: 9999;

  &::before {
    content: "";
    @include cover();
    background-color: #221C35;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s, visibility .5s;
  }

  .links {
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #1B162B;
    color: white;

    ul {
      display: flex;

      &, li {
        padding: 0;
        margin: 0;
      }

      li {
        flex: 1;
        border-right: 3px solid white;

        &:last-child {
          border-right: none;
        }


        &.active {

          a {
            background-color: #221C34;
          }
        }
      }
    }

    a {
      display: block;
      color: white;
      text-align: center;
      padding: 10px;
      transition: background-color .3s;

      p {
        padding-top: 5px;
        margin: 0;
        font-size: 1.3rem;
        line-height: 1.4;
      }

      &:hover {
        background-color: #221C34;
      }

    }
  }

  .close {
    margin-bottom: 20px;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    padding: 0;
  }

  .panel {
    position: fixed;
    z-index: 101;
    height: 100vh;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: palette(brand, purple);
    padding: 20px;
    transform: translateX(100%);
    transition: transform .5s;

    .inner {
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      padding: 10px 10px 40px;

      .strip {
        max-width: 160px;
        margin-bottom: 20px;
      }
    }

    .inner-links {
      a {
        position: relative;
        display: block;
        color: white;
        font-weight: bold;
        font-size: 1.6rem;
        padding-right: 40px;

        .icon {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: transform .3s;
        }

        &:hover {
          .icon {
            transform: translateY(-50%) translateX(10px);

            path {
              fill: palette(brand, blue);
            }
          }
        }
      }
    }

    &.open {
      transform: translateX(0);
    }
  }

  .inner-panel {
    @include cover;
    background-color: palette(brand,purple);
    overflow: auto;
    padding-bottom: 150px;
    transform: translateX(100%);
    transition: transform .3s;

    .not-region, .back {
      cursor: pointer;
      outline: none;
      padding: 0;
      border: none;
      background-color: transparent;
      margin-bottom: 25px;
      font-size: 1.6rem;
      font-family: $heading-font;
      color: white;
    }

    .not-region {
      text-decoration: underline;
    }

    .back {
      transition: color .3s;

      .icon {
        margin-right: 10px;
      }

      &:hover {
        color: palette(brand, blue);

        path {
          fill: palette(brand, blue);
        }
      }
    }

    &.open {
      transform: translateX(0);
    }
  }

  table {
    text-align: left;
    font-size: 1.6rem;

    th {
      padding: 10px 0;
      border: none;
      border-bottom: 1px solid white;
    }

    td {
      padding: 20px 0;
      border: none;
      border-bottom: 1px solid white;
      vertical-align: middle;
    }

    img {
      height: 35px;
      width: auto;
      padding-right: 15px;
    }

    .arrow {

      &:hover {
        path {
          fill: palette(brand, blue);
        }
      }
    }
  }

  .heading-range {
    position: relative;

    .checkbox {
      label {
        line-height: 1.6;

        &::before {
          border-color: white;
        }
      }
    }
  }

  .cutout {
    position: relative;
    &::after {
      content: "";
      @include cover;
      background: linear-gradient(0deg, rgba(34, 28, 53, 1) 0%, rgba(34, 28, 53, 0) 100%);
    }
  }

  &.open {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &::before {
      opacity: .3;
      visibility: visible;
    }
  }
}


.toolbar-page {
  position: relative;

  &::before {
    content: "";
    @include cover;
    opacity: 0.75;
    background-image: linear-gradient(135deg, #1397BB 0%, #221C34 100%);
    z-index: 2;
  }

  .content {
    position: relative;
    z-index: 3;
    color: white;
  }
}

@include breakpoint(small) {
  .toolbar {
    .heading-range {
      padding-right: 160px;

      .checkbox {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}


@include breakpoint(medium) {
  .toolbar-page {
    .content {
      padding: 40px;
    }
  }

  .toolbar {

    .panel {
      border-right: 80px solid #1B162B;
      width: 810px;
      padding: 80px 50px 10px;
      z-index: 99;

      .inner {
        padding: 10px;
      }


      .inner-links li {
        padding-bottom: 25px;
      }
    }

    .inner-panel {
      padding: 10px;

      .back {
        margin-bottom: 40px;
      }

    }

    .close {
      position: absolute;
      right: -55px;
      top: 40px;

      &,
      & > svg {
        width: 25px;
        height: 25px;
      }
    }

    .links {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      box-shadow: 0 10px 30px 0 #221C35;
      overflow: hidden;
      left: auto;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);

      ul {
        display: block;

        li {
          flex: none;
          border-right: none;
          border-bottom: 3px solid white;

          &:last-child {
            border-bottom: 0 solid white;
          }

          &.active {
            border-top-color: white;


          }
        }
      }

      a {
        padding: 20px 7px;
        width: 80px;


        p {
          line-height: 1.5;
        }
      }
    }
  }
}
