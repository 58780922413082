// ==========================================================================
// Footer
// ==========================================================================
#footer {
  position: relative;
  background-color: #29BCEE;
  border-top: 5px solid white;

  &::before {
    content: "";
    @include cover();
    background: rgb(13, 106, 135);
    background: linear-gradient(90deg, rgba(13, 106, 135, 1) 0%, rgba(34, 28, 53, 1) 100%);
  }


  .footer__nav {

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      padding-bottom: 15px;

      &:first-of-type {
        padding-left: 0px;
      }
    }

    a {
      transition: opacity .3s;

      &:hover {
        opacity: .5;
      }
    }
  }

  .nav__item {
    font-size: 18px;
    color: white;
  }

  .footer__copy {
    padding: 2rem 0;

    div {
      line-height: 1.8;
    }

  }

  .socials {
    list-style: none;
    margin: 0;
    padding: 0;

    a {

    }
  }

  .footer__contact {

    a {

      div {
        transition: opacity .3s;

        span {
          color: white !important;
        }
      }

      &:hover {
        div {
         opacity: .5;
        }
      }
    }

    .contact__link {

      margin-bottom: 10px;

      &:hover {

      }
    }
  }
}

@include breakpoint(small) {

  #footer {
    .footer__nav {
      li {
        display: inline-block;
        padding-left: 25px;
      }
    }
  }
}

@include breakpoint(medium) {

  #footer {

    &::before {
    }

    .contact__link {
      font-size: 2.2rem;
    }

    .footer__contact .btn {
      display: inline-block;
      margin-bottom: 30px;
    }

    .footer__copy {
      display: flex;
      justify-content: space-between;
    }
  }
}


@include breakpoint(large) {

  #footer {
    .contact__link {
      font-size: 2.6rem;
    }

  }
}