// ==========================================================================
// #ICONS
// ==========================================================================
.icon {
  @include bg-cover();
  display: inline-block;
  vertical-align: middle;
  background-size: contain;

  > svg {
    display: block;
  }

  path, polygon {
    transition: fill .3s;
  }
}

// ==========================================================================
// #ICONS-COLORS
// ==========================================================================
.icon--white {
  path, circle {
    fill: white;
  }
}

.icon--gray {
  path {
    fill: #9C9C9C;
  }
}

.icon--black {
  path, polygon {
    fill: black !important;
  }
}

// Colors
@each $color, $shades in $palettes {
  $colorName: if($color == grey, '-', '--#{$color}');
  @each $shade, $value in $shades {
    $shadeModifier: if($shade == base, '', '-#{$shade}');

    @if $shade == base {
      $shadeModifier: if($color == grey, '-#{$color}', '');
    } @else {
      $shadeModifier: '-#{$shade}';
    }

    .icon#{$colorName}#{$shadeModifier} {
      path, polygon, rect, circle {
        fill: palette($color, $shade);
      }
    }
  }
}

// ==========================================================================
// #ICONS-SIZE
// ==========================================================================

.icon--15 {
  &,
  & > svg {
    width: 15px;
    height: 15px;
  }
}

.icon--20 {
  &,
  & > svg {
    width: 20px;
    height: 20px;
  }
}

.icon--25 {
  &,
  & > svg {
    width: 25px;
    height: 25px;
  }
}

.icon--30 {
  &,
  & > svg {
    width: 30px;
    height: 30px;
  }
}

.icon--35 {
  &,
  & > svg {
    width: 35px;
    height: 35px;
  }
}

.icon--40 {
  &,
  & > svg {
    width: 40px;
    height: 40px;
  }
}

.icon--50 {
  &,
  & > svg {
    width: 50px;
    height: 50px;
  }
}

.icon--60 {
  &,
  & > svg {
    width: 60px;
    height: 60px;
  }
}

.icon--70 {
  &,
  & > svg {
    width: 70px;
    height: 70px;
  }
}

.icon--100 {
  &,
  & > svg {
    width: 100px;
    height: 100px;
  }
}

.icon--logo {
  &,
  & > svg {
    width: 175px;
    height: auto;

    //@include breakpoint(small) {
    //  width: 220px;
    //}

  }
}

.icon--logo-scotland {
  padding-top: 7px;

  &,
  & > svg {
    width: 250px;
    height: 38px;
  }

  @include breakpoint(small) {
    padding-top: 0;
    &,
    & > svg {
      width: 399px;
      height: 38px;
    }
  }
}

.icon--procure {
  &,
  & > svg {
    width: 180px;
    height: 40px;

    @include breakpoint(small) {
      width: 205px;
      height: 45px;
    }

  }
}


// ==========================================================================
// #ICONS-SOCIAL
// ==========================================================================
.icon--social, .icon--social-flip {
  circle, path, ellipse {
    transition: all .3s
  }
}

.icon--social {
  &:hover {
    circle, ellipse {
      fill: palette(brand, blue);
    }

    path {
      fill: white;
    }

    .invert {
      fill: palette(brand, blue);
    }
  }
}


.icon--social-flip {
  &:hover {
    circle, ellipse {
      fill: palette(brand, purple);
    }

    path {
      fill: white;
    }

    .invert {
      fill: palette(brand, purple);
    }
  }
}

.icon--public {
  fill: palette(brand, blue) !important;
}

.icon--private path {
  fill: #5F2167 !important;
}