.in-your-region {
  .block__info--big {
    border-top-right-radius: 10px;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint(large) {
      padding: 70px;
    }

    .block__info {
      &:before {
        content: unset;
      }

      img {
        max-width: 100%;
      }
    }
  }
}

.tile-framework-sub.tile-result{
  .inner {
    min-height: 336px;

    p.bold {
      min-height: 50px;
    }

    .partner-logo {
      max-height: 100px;
    }

    .project-details {
      position: absolute;
      bottom: 0;
      text-align: center;
      left: 0;
      width: 100%;
      padding: 0 15px;
    }
  }
}