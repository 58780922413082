//=====================================
// #BUTTON
//=====================================
button {
  font-family: $heading-font;
  outline: none;

  &[disabled]{
    opacity: 0.4;
    cursor: not-allowed;
  }
}

@mixin button-unstyled {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;

  &:focus, &:active {
    outline: none;
  }
}


.btn {
  outline: none;
  display: inline-block;
  padding: 8px 35px;
  border: 3px solid palette(brand, blue);
  border-radius: 23px;
  background-color: palette(brand, blue);
  color: palette(brand, purple);
  transition: background 0.2s ease, color 0.2s ease, border .2s ease;
  -webkit-appearance: none;
  font-family: $heading-font;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.4;
  width: auto;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  white-space: nowrap; // Preventing text wrap between viewport widths

  &:hover, &:focus {
    color: #fff;
    background-color: palette(brand, purple);
    border-color: palette(brand, purple);

    svg path {
      fill: white;
    }
  }

  &.is--hidden--large {
    @include breakpoint(large) {
      display: none;
    }
  }

}

.btn--min {
  min-width: 190px;
}

.btn--custom {
  transition: opacity .3s, background 0.2s ease, color 0.2s ease, border .2s ease;

  &:hover {
    opacity: 0.8;
  }
}

.btn--private {
  background-color: palette(brand, private);
  color: white;
  border-color: palette(brand, private);
}

.btn--full {
  width: 100%;
}

.btn--r {
  float: right;
}

.btn--width {
  width: 100%;
  max-width: 200px;
  text-align: center;
}

//=====================================
// #BUTTON-SIZES
//=====================================
.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px;
}

.btn--mid {
  font-size: 2rem;
  padding: 10px 40px;
}

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px;
}

//=====================================
// #BUTTON-COLORS
//=====================================
.btn--white {
  border-color: white;
  color: white;
}

.btn--white-fill {
  border-color: white;
  background-color: white;
  color: black;
}

.btn--fill-blue {
  background-color: palette(blue);
  color: white;

  &:hover {
    background-color: palette(blue, dark);
  }

  &-active {
    background-color: palette(brand, purple);
    border-color: palette(brand, purple);
    color: white;
  }
}

.btn--fill-purple {
  background-color: palette(brand, purple);
  border-color: palette(brand, purple);
  color: white;

  &:hover {
    background-color: palette(blue);
    border-color: palette(blue);
  }
}

.btn--h-white {
  &:hover, &:focus {
    color: palette(brand, purple);
    border-color: white;
    background-color: white;
  }

  &.btn--outline:hover {
    color: palette(brand, purple);
    border-color: white;
    background-color: white;
  }
}

.btn--h-purple {
  &:hover {
    color: white;
    border-color: palette(brand, purple);
    background-color: palette(brand, purple);
  }
}

.btn--bump {
  margin-top: 40px;
}

.btn--outline {
  background-color: transparent;
  color: palette(brand, blue);
}

.btn--outline-white {
  background-color: transparent;
  color: white;
  border-color: white;
}


//=====================================
// #OTHER
//=====================================

.play {
  outline: none;
  cursor: pointer;
  border: none;
  background: transparent;
  color: palette(brand, blue);
  font-family: $body-font;
  font-size: 2rem;

  .icon {
    transform: scale(.8);
    transition: transform .3s;
  }

  span {
    display: inline-block;
    vertical-align: middle;

    &:first-of-type {
      margin-right: 15px;
    }
  }

  &:hover {

    .icon {
      transform: scale(1);

      path {
        fill: palette(brand, purple);
      }
    }
  }
}

#service-picker {

  @include breakpoint-max(large) {

    .btn-service {
      transition: opacity .3s;
      opacity: .6;

      &.active {
        opacity: 1;
      }
    }
  }
  @include breakpoint(large) {


    .btn-service {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 100%;
        margin-top: 15px;
        transform: translateX(-50%);
        height: 15px;
        width: 15px;
        background-color: black;
        border-radius: 50%;
        opacity: 0;
        transition: opacity .3s;
      }

      &.active {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

// BTN SERVICES
.service-btn__hold {
  margin-top: 20px;
  position: relative;

  .btn-service {
    margin-bottom: 20px;
  }


  @include breakpoint(small) {
    margin-bottom: 16vh;
    z-index: 9;
    margin-top: 0;

    .g {
      padding-top: 20px;
      transform: translateY(-50%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  @include breakpoint(large) {
    margin-bottom: 8vh;
  }
}

.service-cat-btn__hold {
  @include breakpoint(medium) {
    padding-left: 100px;
  }
}

.btn-service {
  display: block;
  background-color: palette(brand, blue);
  padding: 5px 0 5px 5px;
  border-radius: 30px;
  color: white;
  transition: background 0.2s ease, color 0.2s ease, border .2s ease;
  -webkit-appearance: none;
  font-family: $heading-font;
  font-weight: bold;
  font-size: 1.6rem;

  img {
    margin-bottom: 0 !important;
  }

  .icon {
    margin-right: 5px;
  }

  @include breakpoint(large) {
    font-size: 1.5rem;
  }

  &--icon-none {
    padding: 13px 12px;
  }

  &--arrow {
    overflow: hidden;
    position: relative;

    &::before, &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      bottom: 0;
      transition: width .3s;
    }

    &::before {
      background-color: rgba(255, 255, 255, .2);
      transform: rotate(5deg) translateY(-50%);
      height: 200%;
      top:50%;
    }

    &::after {
      background-image: url("/dist/img/icons/arrow-right-white.svg");
      background-repeat: no-repeat;
      background-position: right 20px center;
      background-size: 24px;
    }

    &:hover::before {
      width: 80px;
    }
  }


  &--private {
    background-color: palette(brand, private);

    &::before {
      background-color: #221C35;
    }
  }
}

.btn--responsive {
  .btn {
    white-space: normal !important;
  }
}

// Control panel edit btn

.edit-page {
  position: fixed;
  bottom: 0px;
  left: 0;
  background: white;
  padding: 12px;
  z-index: 99995;
  border-top-right-radius: 6px;
  box-shadow: 7px 0px 12px -8px #bfbfbf;
}