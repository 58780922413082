// ==========================================================================
// Promo block
// ==========================================================================
.promo {
  padding: 95px 10px 30px;
  color: white;
}

.promo--search {
  background-color: rgb(217, 5, 115);
}

.promo--region {
  background-color: palette(blue);
  background-image: url(/dist/img/bkg/regional-map.svg);
  background-repeat: no-repeat;
  background-position: right -170px center;
  background-size: 360px;

  p:last-of-type {
    margin-bottom: 0;
  }
}

.tab {
  position: absolute;
  top: 0;
  right: 10px;
  width: 90px;
  height: 120px;
  background-color: #282828;
  transition: background-color .3s;

}

.promo--live-procurement {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  background-color: palette(brand, purple);
  padding: 30px 80px 30px 20px;

  .tab {
    background: transparent;
    padding: 20px 5px;
    right: 0;
    width: 60px;
    height: 100%;

    .icon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 15px;
    }

    &::before {
      content:"";
      @include cover();
      background-color: palette(brand, blue);
      border-left:3px solid white;
      width:200%;
      height: 200%;
      transform: rotate(5deg);
      transition: transform .3s;
    }

    &:hover {
      &::before {
        transform: rotate(5deg) translateX(-10px);
      }

    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  @include breakpoint(medium) {
    background-image: url(/dist/img/bkg/live-procurement-purple.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
}

@include breakpoint(medium) {
  .promo {
    padding: 50px 30px;
  }

  .promo--region {
    background-position: right 20px center;
    background-size: 400px;
  }
}

@include breakpoint(large) {
  .promo--region {
    background-position: right 80px center;
    background-size: 440px;
  }
}