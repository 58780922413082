//==========================================================================
// Abstrakts (ITCSS & BEM Method) Style coding
//==========================================================================

//======================================== CONFIGS
@import '_config/colours';
@import '_config/typography';
//======================================== UTILS
@import '_utils/breakpoints';
@import '_utils/spacing';
@import '_utils/cover';
@import '_utils/background-cover';
@import '_utils/clearfix';
@import '_utils/hidden-visible';
@import '_utils/index';
@import '_utils/index';
//======================================== BASE
@import 'base/reset';
@import 'base/html-body';
@import 'base/typography';
@import 'base/icon';
@import 'base/inputs';
@import 'base/button';
@import 'base/ribbon';
@import 'base/table';
//======================================== LAYOUT
@import 'layout/grid';
// Grid
@import 'layout/wrapper';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/chunk';
//======================================== MODULES
@import 'modules/hero';
@import 'modules/cookie';
@import 'modules/accordion';
@import 'modules/pagination';
@import 'modules/hamburger';
@import 'modules/scroll-to-top';
@import 'modules/cta';
@import 'modules/block';
@import 'modules/tile';
@import 'modules/modal';
@import 'modules/slider';
@import 'modules/sidebar';
@import 'modules/toolbar';
@import 'modules/team';
@import 'modules/preview';
@import 'modules/support';
@import 'modules/promo-block';
@import 'modules/accordion';
@import 'modules/tabs';
@import 'modules/pills';
@import 'modules/search';
@import 'modules/framework-finder';
//======================================== VENDOR
@import 'vendor/flickity';
@import 'vendor/lightbox';
@import 'vendor/glightbox';
@import 'vendor/range-slider';
//======================================== PRIORITY
@import '_utils/text';
//======================================== PRINT
@import '_utils/print';

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .tile-news .tile__image::before {
    height: 15px;
  }

  .block-grid .block__support .people {
    opacity: .7;
    &::before {
      content: none;
    }
  }

  .block-quote::before {
    width: 50px !important;
  }
}

.grecaptcha-badge {
  bottom: 110px !important;
}