.team-page {
  border-top: 10px solid white;


  .member {

    a {
      color: palette(brand, purple);
    }

    &--exec a {
      color: white;
    }

    .cutout {
      position: relative;
      margin-bottom: 10px;

      img {
        transition: opacity .3s;
      }

      &::before {
        content: attr(data-title);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
        font-size: $p-size;
        font-weight: bold;
        transition: opacity .3s;
        z-index: 2;
        opacity: 0;
      }

      &--exec {
        margin-bottom: 5px;


        &::before {
          color: white;
        }

        &::after {
          content: "";
          @include cover;
          background: linear-gradient(0deg, rgba(34, 28, 53, 1) 0%, rgba(34, 28, 53, 0) 100%);
        }
      }
    }

    a {

      &:hover {
        .cutout {

          img {
            opacity: .4;
          }

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}

@include breakpoint(1400px) {
  .team-page {

    .member {
     // lost-column: 1/5;
    }
  }
}