//=====================================
// #RIBBON
//=====================================
.ribbon {
  display: inline-block;
  padding: 30px 120px 30px 30px;
  font-size: 1.8rem;
  box-shadow: 0 4px 10px -1px rgba(35, 28, 53, 0.5);
  border-left: 5px solid palette(brand, blue);
  color: palette(brand, purple);
  line-height: 1.2;
  cursor: pointer;
  position: relative;
  z-index: 2;
  background-color: white;
  font-weight: bold;
  -webkit-appearance: none;
  transition: background 0.2s ease, color 0.2s ease;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80px;
    background-color: palette(brand, blue);
    clip-path: polygon(28% 0, 100% 0, 100% 100%, 0% 100%);
    transition: clip-path .3s;
  }

  &--arrow {
    position: relative;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      background-image: url("/dist/img/icons/arrow-right-white.svg");
      background-repeat: no-repeat;
      background-size: 25px;
      width: 25px;
      height: 18px;
      top: 50%;
      transform: translateY(-50%);
      right: 25px;
      transition: opacity .3s;
    }

    &:hover::after {
      opacity: 1;
    }
  }

  &:hover {

    &::before {
      clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    }

    &::after {
      color: white;
      background-image: url("/dist/img/icons/arrow-right-blue.svg");
    }
  }

  &--plus {

    &::before {
      opacity: .2;
    }

    &::after {
      content: "+";
      color: palette(brand, blue);
      font-size: 3.2rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      right: 20px;
      transition: color .3s;
    }

    &:hover {
      color: palette(brand, purple);

      &::after {
        color: palette(brand, blue);
        background-image: none;
      }
    }
  }

  &--full {
    width: 100%;
  }
}

.ribbon--list {
  margin-bottom: 25px;

  &:hover {
    color: palette(brand, blue);
  }
}

@include breakpoint(small) {
  min-width: 300px;
}