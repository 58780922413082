#smart-search {
  .block__grid > * {
    margin-bottom: 45px;
  }

  #search-filters {
    position: relative;

    & > div {
      padding: 20px 0 0;

      .btn {
        margin: 0 10px 20px 0;
      }

      &.filters--mobile {
        left: 0;

        .filter-toggle {
          font-family: $heading-font;
          font-weight: bold;
          font-size: 1.6rem;
          display: block;
          padding: 15px 49px 15px 25px;
          margin: 20px auto;
          border-radius: 30px;
          width: 100%;
          text-align: center;
          background-color: palette(brand, purple);
          position: relative;

          &:after {
            background-image: url(/dist/img/icons/arrow-down-blue.svg);
            background-size: 16px;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            position: absolute;
            right: 16px;
            top: 11px;
            content:"";
          }

          &--active {
            &:after {
              transform: rotate(180deg);
            }
          }
        }

        &--fixed {
          top: 57px;
          position: fixed;
          padding: 0 2rem;
        }

        &--open {
          height: 100%;
          overflow-y: auto;
          padding-bottom: 57px;

          .btn {
            display: inline-block;

            &:nth-child(2n+1){
              margin-right: 0;
            }

            width: 100%;

            @include breakpoint("medium") {
              width: calc(50% - 5px);
            }
          }
        }

        &:not(.filters--mobile--open){
          .btn {
            display: none;
          }
        }
      }
    }

    .filters {
      background: white;
      position: absolute;
      top: 0;
      z-index: 999;

      &--fixed {
        top: 57px;
        position: fixed;
        padding-right: 40px;
        margin-left: -15px;
        padding-left: 15px;
      }
    }

    .ghost {
      visibility: hidden;
    }


  }

  .input--outline {
    padding-left:25px;
    line-height: 1.5;
    font-size: 1.6rem;
  }

  .select--white {
    line-height: 1.5;

    option {
      background-color: inherit;
    }
  }

  .tile-result {
    height: auto;
    position: relative;

    &.sidebar-blocks {
      max-width: none;
      height: 400px;

      .sidebar__contact {
        height: 100%;

        .inner {
          min-height: calc(100% - 79px);

          .cutout {
            max-height: 100%;
            width: 210px;
            right: -15px;

            @include breakpoint("medium") {
              right: 0;
            }

            img {
              width: 100%;
            }
          }
        }
      }
    }

    &.member {
      a {
        color: palette(brand, purple);
      }

      .cutout {
        position: relative;
        margin-bottom: 10px;

        img {
          transition: opacity .3s;
        }

        &::before {
          content: attr(data-title);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          text-align: center;
          font-size: $p-size;
          font-weight: bold;
          transition: opacity .3s;
          z-index: 2;
          opacity: 0;
        }
      }

      a {
        &:hover {
          .cutout {

            img {
              opacity: .4;
            }

            &::before {
              opacity: 1;
            }
          }
        }
      }
    }

    .tile__image {
      img {
        width: 100%;
      }
    }

    .tag-pill {
      background-color: palette(brand, blue);
      white-space: nowrap;
      z-index: 3;
      line-height: 1;
      position: absolute;
      top: 0;
      left: 20px;
      padding: 11px 20px;
      color: white;
      border-radius: 30px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    /*


    &:not(.bump){
      margin-bottom: 0;
    }

    &.preview--block {
      height: 100%;
      margin-bottom: 0;

      a {
        max-height: none;
        height: 100%;
      }
    }
    */

    /*


    &.tile-framework-sub {
      height: 100%;

      .content {
        height: inherit;

        .inner {
          height: calc(100% - 73px);
        }
      }
    }



     */
  }
}
