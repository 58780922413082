//=====================================
// #HELPERS-TEXT
//=====================================

.tt-u {
  text-transform: uppercase;
}

.bold, strong {
  font-weight: 800;
}

.normal {
  font-weight: 400;
}

em, .italic {
  font-style: italic;
}

.flat {
  margin-bottom: 10px;
}

.under {
  text-decoration: underline;
}

.ta-c, .u-text-center {
  text-align: center;

  > * {
    text-align: center;
  }
}

.ta-l, .u-text-left {
  text-align: left;
}

.ta-r, .u-text-right {
  text-align: right;
}


@include breakpoint(small) {
  .ta-r--sm {
    text-align: right;
  }
}


@include breakpoint(medium) {
  .ta-r--md {
    text-align: right;
  }
}


.td-n {
  text-decoration: none;
}

.a11y {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.tagline {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.3;
  text-transform: uppercase;
  padding-bottom: 0;
  letter-spacing: 1px;
}

.location {
  position: relative;
  padding-left: 40px;

  span {
    position: absolute;
    left: 0;
    top: 8px;
  }
}