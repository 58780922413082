.tile-job {
  display: block;
  background: #FFFFFF;
  color: palette(brand, purple);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.10);
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;

  .tag {
    position: relative;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 15px;
    padding: 7px 15px 7px 31px;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 1.6rem;
      width: 1.6rem;
      border-radius: 50%;
      left: 8px;
      background-color: palette(brand, blue);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: palette(brand, blue);
      opacity: .2;
      border-radius: 30px;
    }
  }

  .left, .inner {
    padding: 20px;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .left {
    border-bottom: 1px solid #D0D0D0;
  }

  .arrow {
    text-align: right;
    padding: 10px 20px;
    background-color: palette(brand, blue);
  }

  &--arc-blue {
    .tag::before, .tag::after, .arrow {
      background-color: palette(brand, arc-blue);
    }
  }

  &--lungfish-black {
    .tag::before, .tag::after, .arrow {
      background-color: palette(brand, lungfish-black);
    }
  }
}

.tile__hold {
  margin-bottom: 25px;
}

.tile-news__hold {
  margin-bottom: 25px;
}

.tile-overlap {
  position: relative;
  display: block;
  margin-bottom: 25px;

  .tag-pill {
    white-space: nowrap;
    z-index: 3;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 20px;
    padding: 11px 40px 11px 20px;
    color: white;
    border-radius: 30px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .tile__image {
    position: relative;

    .tag {
      color: white;
      font-size: 1.6rem;
      position: absolute;
      font-weight: bold;
      top: 0;
      left: 0;
      padding: 15px 50px 15px 20px;
      min-width: 175px;
      clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    }

    &--logo {
      &::before {
        content: "";
        @include cover;
        opacity: 0.84;
        background-image: linear-gradient(-58deg, rgba(34, 28, 53, 0.00) 0%, #221C35 100%);
      }

      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);
        max-width: 50%; //IE 11 FIX
      }
    }
  }

  .tile__text {
    position: relative;
    margin-top: -60px;
    padding-right: 30px;

    .inner {
      background-color: white;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 35px 20px;
      color: palette(brand, purple);
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0);
      transition: box-shadow .3s, transform .3s;
    }

    .h4 {
      margin-bottom: 10px;
    }

    .tagline {
      font-size: 1.6rem;
    }

    .read {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &--private {
    .read {
      color: palette(brand, private);
    }

    svg path {
      fill: palette(brand, private);
    }
  }


  &:hover {

    .tile__text .inner {
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
      transform: translateY(-25px);
    }
  }
}

.tile-news {
  position: relative;
  display: block;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 50px;
  background-color: palette(grey, x-light);
  color: palette(brand, purple);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0);
  transition: box-shadow .3s;

  .tile__image {
    position: relative;
    font-size: 0;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50px;
      background-color: palette(brand, blue);
      clip-path: polygon(0 80%, 100% 80%, 100% 100%, 0% 100%);
      transition: clip-path .3s;
    }
  }

  .tile__text {
    padding: 20px 20px 0 20px;
  }


  .link--fake {
    text-decoration: underline;
    line-height: 1.3;
  }

  .read {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    padding: 20px 20px 0;
  }

  &--private {
    .tile__image::before {
      background-color: #5F2167;
    }
  }

  &--dark {
    background-color: palette(brand, purple);

    .read {
      background-image: url("/dist/img/icons/arrow-right-white.svg");
      background-repeat: no-repeat;
      background-position: right 20px top 20px;
      background-size: 24px;
    }
  }

  &:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);

    .tile__image::before {
      clip-path: polygon(0 80%, 100% 0, 100% 100%, 0% 100%);
    }
  }

  &--purple-dark {
    .tile__image::before {
      background-color: #221C34;
    }
  }

  &--blue {

  }

  &--blue-dark {
    .tile__image::before {
      background-color: palette(brand, insight);
    }
  }
}

.tile-framework, .tile-framework-sub {
  height: 100%;
  position: relative;
  display: block;

  .tag {
    white-space: nowrap;
    z-index: 3;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 20px;
    padding: 5px;
    color: white;
    border-radius: 30px;
    transform: translateY(-50%);
    padding-right: 25px;

    .icon {
      margin-right: 5px;
    }
  }
}

.tile-framework-sub {
  display: block;
  transition: transform .3s;

  .tag {
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .content {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 15px 0 rgba(34, 28, 53, .2);
    transition: box-shadow .3s;
  }

  .inner {
    padding: 45px 15px 5px;
    position: relative;
    overflow: hidden;
    color: palette(brand, purple);
  }

  .partner-logo {
    //width: 100%;
    margin: 10px auto 30px;
    max-width: 240px;

    img {
      max-height: 80px;
      width: auto;
      height: auto;
      margin-bottom: 0 !important;
    }
  }

  .view {
    position: relative;
    background-color: palette(brand, blue);
    color: white;
    height: 73px;
    display: flex;
    align-items: center;
    padding: 20px;

    p {
      margin: 0;
    }

    &-old {
      background-color: palette(grey, mid-dark);
    }

    .arrow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        @include cover;
        border-left: 3px solid white;
        transform: rotate(5deg);
        height: 200%;
      }
    }
  }

  .icon--bkg {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(65px, 70px);

    &, svg {
      width: 260px;
      height: 260px;

      path, polygon {
        fill: #221C35;
        opacity: .1;
      }
    }
  }

  &--private {

    .icon--bkg {
      &, svg {
        path, polygon {
          fill: white;
          opacity: .1;
        }
      }
    }

    .tag {
      background-color: #5F2167;
    }

    .inner {
      * {
        color: white;
      }
    }

    .content {
      background-color: #221C35;
    }

    .view {
      background-color: #5F2167;
    }
  }

  &:hover {
    transform: translateY(-15px);

    .content {
      box-shadow: 0 0 25px 3px rgba(34, 28, 53, 0.6);
    }
  }
}

.tile-framework {

  .content {
    height: 100%;
    position: relative;
    overflow: hidden;
    background: #FFFFFF;
    padding: 50px 70px 60px 30px;
    color: palette(brand, purple);
    border-radius: 10px;
    box-shadow: 0 0 16px -8px #221C35;
  }

  .learn {
    position: absolute;
    bottom: 20px;
    left: 30px;
    margin-bottom: 0;
  }

  .arrow {
    padding: 22px 18px;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 60px;
    bottom: -1px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: palette(brand, blue);
    will-change: clip-path;
    clip-path: polygon(30% 0%, 100% 0, 100% 100%, 0% 100%);
    transition: clip-path .3s, width .3s;
  }

  &--private {
    .tag {
      background-color: #5F2167;
    }

    .content {
      background-color: palette(brand, purple);

      .inner {
        color: white;

        .icon--bkg {
          svg {
            path, polygon {
              fill: #fff;
              opacity: .1;
            }
          }
        }
      }
    }

    .view {
      background-color: #5F2167;
    }

    .arrow {
      background-color: #5F2167;
    }
  }
}

.playbook__hold {
  position: relative;
  z-index: 3;
  margin-top: -60px;

  @include breakpoint(large) {
    margin-top: -80px;
  }
}

.tile-playbook {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  padding: 30px 20px 75px 20px;
  height: 100%;

  * {
    color: #221C35;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 8px;
    background-color: palette(brand, blue);
  }

  .heading {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .p {
      flex: 1;
      line-height: 1.5;
      margin-bottom: 0;
    }

    .counter {
      height: 45px;
      width: 45px;
      background: #E4E4F6;
      border-radius: 7px;
      padding-top: 15px;
      margin-right: 15px;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .bar {
    position: relative;
    height: 20px;
    background-image: linear-gradient(270deg, #20A355 0%, #FF7900 66%, #FF0000 100%);
    border-radius: 10px;
    border: 0;

    .bar-inner {
      position: relative;
      margin-left: -13px;
      margin-right: 10px;
    }

    .dot {
      background: #221C35;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 3px;
      transform: translateX(-50%);

      &::before, &::after {
        content: "";
        position: absolute;
        top: 6px;
        left: 6px;
        height: 34px;
        width: 34px;
        border-radius: 50%;
        border: 1px solid #221C35;
        transform: translateY(-50%) translateX(-50%);
      }

      &::after {
        height: 45px;
        width: 45px;
      }

      &--1 {
        left: 10%;
      }

      &--2 {
        left: 20%;
      }

      &--3 {
        left: 30%;
      }

      &--4 {
        left: 40%;
      }

      &--5 {
        left: 50%;
      }

      &--6 {
        left: 60%;
      }

      &--7 {
        left: 70%;
      }

      &--8 {
        left: 80%;
      }

      &--9 {
        left: 90%;
      }

      &--10 {
        left: 100%;
      }
    }
  }

  .read {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    padding: 20px 20px 0 20px;

    p {
      color: palette(brand, blue);
      margin-bottom: 0;
      text-decoration: underline;
    }
  }

  &--promo {
    background-image: linear-gradient(180deg, #221C35 20%, #009CBD 100%);

    .heading {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    * {
      color: white;
    }

    .read p {
      color: white !important;
    }

    .bar {
      .dot {
        background: white;

        &::before, &::after {
          border-color: white;
        }
      }
    }
  }
}

.tile-speaker {
  border-radius: 10px;
  overflow: hidden;
  background-color: palette(grey, x-dark);
  color: white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);

  .inner {
    padding: 25px 122px 25px 25px;
    position: relative;
    height: 100%;
  }


  .cutout {
    font-size: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 120px;
  }


  .arrow {
    display: block;
    text-align: right;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, .2);
  }

  &--scape {
    background-color: palette(brand, blue);
  }

  &--guest {
    background-color: palette(brand, purple);
  }
}

.tile-sector {
  display: block;
  position: relative;
  margin-bottom: 30px;

  .inner {
    position: relative;
    padding: 30px 55px 30px 70px;
    background-color: white;
    margin-right: 30px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: -40px;
    color: palette(brand, purple);
    background-image: url("/dist/img/icons/arrow-right-blue.svg");
    background-size: 25px 17px;
    background-repeat: no-repeat;
    background-position: center right 20px;
    transition: box-shadow .3s, transform .3s;

    img {
      position: absolute;
      left: 20px;
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);
    }

    > * {
      margin-bottom: 0;
    }
  }

  &:hover {
    .inner {
      box-shadow: 0 0 16px -8px #221C35;
      transform: translateY(-30px);
    }
  }
}

.tile-research {
  background-color: palette(grey, x-light);
  color: palette(brand, purple);
  display: flex;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  .tile__image {
    position: relative;
    width: 20%;
  }

  .read {
    text-decoration: underline;
    color: palette(blue);
  }

  .tile__text {
    padding: 20px;
    width: 80%;
    border-left: 5px solid palette(blue);
  }
}

.tile-simple {
  display: block;
  color: palette(brand, purple);
  margin-bottom: 35px;

  img {
    margin-bottom: 15px;
  }

  .heading {
    position: relative;
    padding-right: 30px;

    p {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 22px;
      height: 16px;
      box-shadow: 0 0 16px -8px #221C35;
      transition: transform .3s;
    }
  }

  &:hover .heading::before {
    transform: translateY(-50%) translateX(10px);
  }
}

.tile-stat {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
  padding-top: 100%;
}

@include breakpoint(small) {

  .tile-speaker {
    position: relative;
    overflow: hidden;
    height: 100%;
    min-height: 180px;

    .inner {
      padding: 20px 150px 50px 20px;
    }

    .socials {
      position: absolute;
      left: 20px;
      bottom: 0;
    }

    .cutout {
      right: 30px;
    }

    a.arrow {
      &:hover {
        &::before {
          transform: translateX(-10px) rotate(3deg);
        }
      }
    }

    .arrow {
      display: flex;
      align-items: flex-end;
      position: absolute;
      top: -1px;
      right: -1px;
      width: 55px;
      bottom: -1px;
      text-align: center;
      padding: 15px 0;
      background-color: transparent;

      &::before {
        content: "";
        @include cover();
        background-color: rgba(0, 0, 0, 0.2);
        border-left: 2px solid white;
        height: 200%;
        width: 200%;
        transform: rotate(3deg);
        transition: transform .3s;
      }

      span {
        position: relative;
        margin: 0 auto;
      }
    }

    &--scape {
      .arrow::before {
        background-color: #0C7E9D;
      }
    }

    &--guest {
    }
  }

  .tile-job {
    overflow: hidden;
    transition: transform .3s, box-shadow .3s;

    .tag {
      margin-bottom: 30px;
    }

    .left {
      border-bottom: none;
    }

    .right {
      border-left: 1px solid #D0D0D0;
      position: relative;
    }

    .left {
      padding: 40px 25px;
    }

    .inner {
      padding: 40px 100px 40px 25px;
      max-width: 700px;
    }


    .arrow {
      background-color: transparent;
      display: flex;
      align-items: flex-end;
      position: absolute;
      top: -1px;
      right: -1px;
      width: 66px;
      bottom: -1px;

      .icon {
        position: relative;
      }

      &::before {
        content: "";
        @include cover;
        background-color: palette(brand, blue);
        transform: rotate(3deg);
        height: 200%;
        width: 200%;
        transition: transform .3s;
      }
    }

    &--arc-blue {
      .arrow::before {
        background-color: palette(brand, arc-blue);
      }
    }

    &--lungfish-black {
      .arrow::before {
        background-color: palette(brand, lungfish-black);
      }
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);

      .arrow::before {
        transform: translateX(-20px) rotate(5deg);
      }
    }
  }

  .tile-framework {
    transition: transform .3s, box-shadow .3s;

    .inner {
      position: relative;
    }

    .tagline, .inner {
      z-index: 2;
      transition: transform .3s;
      will-change: transform;
    }


    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
    }
  }
}

@include breakpoint(medium) {

  .tile-speaker {
    min-height: 140px;

    .inner {
      padding: 20px 150px 50px 20px;
    }

    .cutout {
      right: 25px;
      width: 140px;
    }
  }

  .tile-framework-sub__pull {
    position: relative;
    padding-bottom: 0 !important;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
      background-color: white;
      height: 104px;
    }
  }

  .tile-job {

    .left {
      padding: 40px 25px;
    }

    .inner {
      padding: 40px 130px 40px 40px;
    }
  }
}

@include breakpoint(large) {

  .tile-speaker {

    .inner {
      padding: 20px 170px 50px 20px;
    }

    .cutout {
      right: 15px;
      width: 160px;
    }
  }
}