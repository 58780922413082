#back-to-top {
  position: fixed;
  text-align: center;
  padding-top: 12px;
  bottom: 30px;
  right: 30px;
  background: palette(brand, blue);
  color: white;
  font-size: 3rem;
  z-index: 5;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s, opacity 1.2s, visibility 1.2s;

  &.visible {
    opacity: 0.7;
    visibility: visible;

    &:hover {
      opacity: 1;
    }
  }
}