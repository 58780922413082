// ==========================================================================
// TABLES MODULE
// ==========================================================================
.cpv {
  .id {
    white-space: nowrap;
    font-weight: 500;
  }

  th, td {
    border: 1px solid palette(grey, light);
    display: table-cell;
    padding: 10px;
  }

  th {
    display: table-cell;

    &[scope=row] {
      width: 25%;
    }
  }
}

table,
.table {
  width: 100%;
  border-collapse: collapse;

  tr {

    margin-bottom: 30px;
  }

  th, td {
    display: block;
    @include breakpoint(small) {
      border: 1px solid palette(grey, light);
      display: table-cell;
      padding: 10px;
    }
  }

  td {
    margin-bottom: 20px;
    vertical-align: top;


    @include breakpoint(small) {
      margin: 0;
    }
  }

  th {
    font-weight: bold;
    vertical-align: top;
    display: none;

    @include breakpoint(small) {
      display: table-cell;
      &[scope=row] {
        width: 25%;
      }
    }
  }

  caption {
    width: 100%;
    margin-bottom: 15px;
  }

  > tfoot {
    td {
      border: none;
      text-align: right;
    }
  }
}

.table--stripped {
  thead tr,
  tbody tr:nth-child(even) {
    background-color: palette(grey, x-light);
  }
}


.table--borderless {
  tr, th, td {
    border: none;
  }

  th {
    padding-left: 0;
  }
}

.table--align-right {
  tr {
    display: block;
    padding-bottom: 15px;
    margin: 0;
    overflow: auto;
  }

  th {
    padding: 0;
    width: 50%;
    float: left;
  }

  td {
    padding: 0;
    float: right;
  }
}


.stats {
  table {
    td {
      margin-bottom: 0;
      margin-top: 10px;
    }

    th {
      margin-bottom: 10px;
    }
  }
}

table.styled {

  th {
    display: table-cell !important;
  }

  .logo {
    width: 100px;
    padding-top: 40px;
  }

  .nowrap {
    white-space: nowrap;
  }

  td {

    border-left: 0;
    border-right: 0;

  }

  tr {

    border-bottom: 1px solid palette(grey, light);

    &:first-child {
      td {
        border-top: none !important;
      }
    }

    &:last-child {
      border-bottom: none !important;

      td {
        border-bottom: none !important;
      }
    }
  }

  p {
    line-height: 1.3;
  }


  @include breakpoint(medium) {
    td {
      padding-top: 20px;
      padding-bottom: 40px;
      padding-right: 35px;
    }

    .logo {
      padding-top: 0;
    }

    .ta-r {
      padding-right: 0 !important;
      vertical-align: middle;
    }
  }
}

.table--documents {
  border-collapse: separate;
  border-spacing: 0 20px;

  @include breakpoint-max(small) {
    .file-date, .file-size {
      display: none !important;
    }
  }

  tr {
    margin-bottom: 0;
  }

  td {
    line-height: 1.9;
  }

  thead {
    @include breakpoint(small) {
      margin-bottom: 12px;
    }

    th {
      font-weight: normal;
      text-align: left;

      @include breakpoint(small) {
        padding: 28px 28px 0 28px;
      }
    }
  }

  tbody {
    tr {
      border-radius: 20px;
      margin-bottom: 20px;

      td {
        padding: 14px;
        background: #FFFFFF;

        @include breakpoint-max(small) {
          margin-bottom: 0;
        }

        @include breakpoint(small) {
          padding: 22px;
        }

        @include breakpoint(medium) {
          padding: 28px;
        }

        &:first-child {
          font-weight: bold;

          @include breakpoint-max(small) {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }

          @include breakpoint(small) {
            max-width: 200px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
          }
        }

        &:last-child {
          position: relative;
          padding: 28px;

          @include breakpoint-max(small) {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
          }

          @include breakpoint(small) {
            border-bottom-right-radius: 15px;
            border-top-right-radius: 15px;
          }

          a {
            width: 100%;
            height: 100%;
            transition: width 0.2s;

            display: flex;
            justify-content: center;
            align-items: flex-start;

            @include breakpoint-max(small) {
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
            }

            @include breakpoint(small) {
              border-top-right-radius: 15px;
              border-bottom-right-radius: 15px;

              &:hover {
                svg {
                  top: 4px;
                }
              }
            }

            svg {
              position: relative;
              top: 0;
              width: 22px;
              height: auto;
              z-index: 2;
              transition: top 0.2s;
            }
          }
        }
      }
    }
  }
}

.table--uploads {
  border-collapse: separate;
  border-spacing: 0 20px;
  margin: 0 auto;

  @include breakpoint-max(small) {
    text-align: center;
    max-width: 400px;
  }

  &__container {
    width: 100%;
  }

  tr {
    margin-bottom: 0;
  }

  td {
    line-height: 1.9;
  }

  thead {
    @include breakpoint(small) {
      margin-bottom: 12px;
    }

    th {
      font-weight: normal;
      text-align: left;

      @include breakpoint(small) {
        padding:20px 16px 0 0px
      }
    }
  }

  tbody {
    tr {
      border-radius: 20px;
      margin-bottom: 20px;

      td {
        padding: 14px;
        background: #FFFFFF;

        @include breakpoint-max(small) {
          margin-bottom: 0;
          width: 100%;
        }

        @include breakpoint(small) {
          padding: 20px 18px 20px 0px;
        }

        @include breakpoint(medium) {
          padding: 20px 16px 20px 0px;
        }

        @include breakpoint(large) {
          padding: 35px 16px 20px 0px;
        }

        &.preview {
          padding: 10px;
          vertical-align: middle;
          min-width: 160px;

          img, svg {
            display: block;
          }

          svg {
            width: 100%;
            height: auto;
          }

          .preview__file {
            position: relative;
          }

          .preview__file__text {
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            text-align: center;
            font-size: 12px;
            text-transform: uppercase;
          }

          @include breakpoint(small) {
            padding: 10px;
          }

          @include breakpoint(medium) {
            padding: 12px 20px 12px 12px;
          }
        }

        &:first-child {
          font-weight: bold;

          img, svg {
            border-radius: 8px;
          }

          @include breakpoint-max(small) {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }

          @include breakpoint(small) {
            width: 116px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
          }
        }

        &:last-child {
          position: relative;
          padding: 20px 16px 20px 0px;

          @include breakpoint-max(small) {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
          }

          @include breakpoint(small) {
            border-bottom-right-radius: 15px;
            border-top-right-radius: 15px;
          }

          @include breakpoint(medium) {
            padding: 20px 16px 20px 0px;
          }

          @include breakpoint(large) {
            padding: 35px 16px 20px 0px;
          }
        }
      }
    }
  }
}