.block-playbook-bar {
  position: relative;
  margin-right: -18px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100vw;
    background-image: linear-gradient(181deg, #221C35 0%, #009CBD 100%);
  }

  .active-page-button {
    width: 100vw;
    position: relative;
    border: none;
    margin-right: -2rem;
    margin-left: -2rem;
    background-color: #1b162a;
    padding: 15px 2rem;

    p {
      margin: 0;
    }
  }

  @include breakpoint-max(medium) {
    ul {
      display: none;

      &.open {
        display: block;
        padding-top: 6vh;
        padding-bottom: 6vh;
      }
    }
    .active-page-button {
      &::before {
        content: "";
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(/dist/img/icons/arrow-down-blue.svg);
        background-repeat: no-repeat;
        background-size: 22px;
        width: 22px;
        height: 37px;
        background-position: 100%;
        transition: top .3s;
      }
    }
  }

  ul {
    position: relative;
    z-index: 2;
    padding-left: gutter(1.5);
    padding-right: gutter(1.5);

    a {
      color: white;
      display: block;
      position: relative;
      padding-right: 14px;

      &.active {
        font-weight: bold;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 8px;
          border-radius: 20px;
          background: palette(brand, blue)
        }
      }
    }
  }

  @include breakpoint(small) {
    .active-page-button {
      width: 100%;
      margin: 0;
    }

    &::before {
      width: 100%;
    }
  }

  @include breakpoint(medium) {
    margin-right: 0;
    padding-top: 12vh;
    padding-bottom: 12vh;
    background: none;


    &::before {
      width: 100vw;
    }

    ul {
      padding-left: 0;
      padding-right: 0;
    }

    .active-page-button {
      display: none;
    }

  }
}

.block-playbook-hold {
  @include breakpoint(medium) {
    padding-left: 40px;
  }
  @include breakpoint(large) {
    padding-left: 80px;
  }
}

.block-playbook-rating {

  p, .bar {
    display: inline-block;
  }

  p {
    margin-right: 20px;
  }


  .bar {
    width: 100%;
    position: relative;
    height: 20px;
    margin-top: 25px;
    background-image: linear-gradient(270deg, #20A355 0%, #FF7900 66%, #FF0000 100%);
    border-radius: 10px;
    border: 0;

    .bar-inner {
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
    }

    .marker {
      position: absolute;
      top: -7px;
      width: 1px;
      background: white;
      height: 32px;

      &::before {
        content: "";
        position: absolute;
        top: 100%;
        font-size: 1.6rem;
        color: white;
        padding-top: 15px;
        transform: translateX(-50%);
      }

      &--start {
        left: 0;

        &::before {
          content: "Low";
        }
      }

      &--middle {
        left: 50%;

        &::before {
          content: "Moderate";
        }
      }

      &--end {
        left: 100%;

        &::before {
          content: "High";
        }
      }
    }

    .dot {
      background: white;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 3px;
      transform: translateX(-50%);

      &::before, &::after {
        content: "";
        position: absolute;
        top: 6px;
        left: 6px;
        height: 34px;
        width: 34px;
        border-radius: 50%;
        border: 1px solid white;
        transform: translateY(-50%) translateX(-50%);
      }

      &::after {
        height: 45px;
        width: 45px;
      }

      &--1 {
        left: 0;
      }

      &--2 {
        left: 23px;
      }

      &--3 {
        left: 30%;
      }

      &--4 {
        left: 40%;
      }

      &--5 {
        left: 50%;
      }

      &--6 {
        left: 60%;
      }

      &--7 {
        left: 70%;
      }

      &--8 {
        left: 80%;
      }

      &--9 {
        left: 90%;
      }

      &--10 {
        left: 100%;
      }
    }
  }

  @include breakpoint(medium) {
    p, .bar {
      display: inline-block;
      vertical-align: top;
    }

    p {
      padding-top: 20px;
    }

    .bar {
      max-width: 500px;
    }
  }
}


.block-intro {
  position: relative;
  z-index: 4;

  .intro-content {
    padding: 20px;
    margin-top: -40px;
    background-color: white;
    border-top: 8px solid palette(brand, blue);


    .g {
      margin-right: -40px;
    }

    .tagline {
      font-size: 1.4rem;
    }

    &--border-blue {
    }

    &--dark {
      background-color: palette(brand, purple);
    }
  }

  &--private {
    .intro-content {
      border-color: palette(brand, private);
    }
  }

  &--netzero {


  }

  .intro-slider {
    padding-top: 30px;

    .slide {
      width: 100%;
      margin-right: 20px;

      .item {
        padding-bottom: 20px;
      }
    }

    .slider-video--custom {
      display: block;
      position: relative;
      width:100%;
    }

    .slider-video {
      display: block;
      position: relative;

      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .6;
        transition: opacity .3s;
      }

      &:hover {
        .play {
          opacity: 1;
        }
      }
    }

    &--side {

      .slide {
        position: relative;
        margin-bottom: 15px;

        .caption {
          position: absolute;
          left: 0;
          bottom: 0;
          background: white;
          max-width: 100%;
          padding: 20px 40px 20px 20px;

          p {
            margin: 0;
          }
        }
      }
    }

    &--pull-up {

    }
  }

  .intro-slider-toggle {
    .icon {
      margin-left: 15px;
      cursor: pointer;
    }
  }
}


.block-grid {

  .block__info--find {
    height: 100%;
  }

  .block__hold {
    margin-bottom: 20px;
  }

  .block__image {
    position: relative;
    min-height: 300px;
    height: 100%;
  }

  .block__support {
    min-height: 330px;

    .people {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding-right: 50px;
      z-index: 0;
      opacity: .8;
      transition: opacity .3s;

      @include breakpoint-max(small) {
        img {
          max-width: 285px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, black 5%, rgba(0, 0, 0, 0) 60%);
      }
    }
  }

  .block__default {
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: 40px 70px 60px 20px;
    display: block;
    border-radius: 10px;
    background-color: palette(brand, purple);
    color: white;
    transition: transform .3s, box-shadow .3s;

    &.block__contact {
      padding: 40px 70px 30px 20px;
    }

    .link-text {
      position: absolute;
      bottom: 30px;
      left: 20px;
      margin-bottom: 0;
      font-size: 1.6rem;
    }

    p a {
      &:hover {
        text-decoration: underline;
      }
    }

    .arrow {
      padding: 20px 16px;
      text-align: center;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      position: absolute;
      top: -1px;
      right: -1px;
      width: 58px;
      bottom: -1px;

      .icon {
        position: relative;
      }

      &::before {
        content: "";
        @include cover();
        border-left: 3px solid white;
        background-color: palette(brand, blue);
        transform: rotate(3deg);
        width: 200%;
        height: 200%;
      }
    }
  }

  .block__quote {
    overflow: visible;
    padding: 50px 20px 40px;
    min-height: auto;
    box-shadow: 0 17px 28px -22px #221C35;

    P:last-child {
      margin: 0;
    }

    .icon {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      left: 20px;
    }
  }


  a.block__default {

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);

      .arrow {
        clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
      }

      .people {
        opacity: .2;
      }
    }
  }
}

.block__info--big {
  position: relative;
  height: 100%;
  padding: 20px;

  .ta-r {
    position: relative;
  }

  .block__info {
    height: auto;


    @include breakpoint(medium) {
      width: 50%;
    }
  }

  .bottom {
    width: 100%;
    position: relative;

    @include breakpoint(small) {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 20px;
    }

    @include breakpoint(medium) {
      padding: 50px;
    }
  }

  @include breakpoint(medium) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.block__image, .block__info--find, .block__info {
  border-radius: 10px;
  overflow: hidden;
}


.block__info--find-hold {
  @include breakpoint(medium) {
    margin-top: -270px;
    padding-top: 40px;


  }
  @include breakpoint(large) {
    margin-top: -310px;
  }
}

.block__info--find {
  position: relative;
  color: white;
  padding: 30px;
  display: flex;
  align-items: center;

  .inner {
    width: 100%;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .block__info {
    &::before {
      content: none !important;
    }
  }
}

.block__info {
  height: 100%;
  position: relative;
  display: block;
  background-color: white;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.10);
  color: palette(brand, purple);
  padding: 40px 30px 50px;
  transition: box-shadow .3s;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    height: 50px;
    background-color: palette(brand, blue);
    clip-path: polygon(0 0, 100% 0, 100% 20%, 0 20%);
    transition: clip-path .3s;
  }

  &--dark {
    background-color: #333;
    color: white;
  }

  &:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);

    &::before {
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0 20%);
    }
  }


  .arrow {
    position: absolute;
    bottom: 20px;
    left: 30px;
  }

  .bump {
    display: inline-block;
    margin-bottom: 20px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.block__grid {

  > * {
    margin-bottom: 25px;
  }

  .block__grid--split {
    margin-bottom: 0 !important;

    > * {
      margin-bottom: 25px;
      height: auto;
    }
  }
}

.block-logo-slider {

  .blue,
  .caption {
    padding: 10px;

    p {
      margin: 0;
    }
  }

  .hold {
    background-color: palette(grey, x-light);
    //padding: 40px 0;
  }

  .logo-slider {
    padding: 20px 0;

    &--mono .slide {

      &.is-selected {
        z-index: 9;
      }

      a {

        img {
          filter: gray;
          -webkit-filter: grayscale(1);
          filter: grayscale(1);
          max-height: 100px;
          transition: filter .3s;

        }

        &:hover {
          img {
            filter: none;
          }
        }
      }
    }
  }

  .controls {
    padding-bottom: 30px;
    text-align: right;
  }

  &--private {
    .controls path {
      fill: palette(brand, purple);
    }
  }

  .slide {
    align-items: center;
    justify-content: center;

    > * {
      margin-bottom: 30px;
      padding: 0 15px;
    }
  }

  .arrow {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0px 10px;

    .icon {
      &,
      & > svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.block-info__hold {
  position: relative;
  z-index: 2;
  margin-top: -65px;

  &--large {
    margin-top: -145px;
  }
}

.block-info {
  position: relative;
  margin-top: 20px;
  border-top: 8px solid palette(brand, blue);
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  &--tag {
    padding: 70px 20px 20px;
  }

  .tag {
    color: white;
    font-size: 1.6rem;
    position: absolute;
    font-weight: bold;
    top: 0;
    right: 0;
    padding: 15px 50px 15px 30px;
    min-width: 185px;
    clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
  }

  .info__head {
    border-bottom: 1px solid #D0D0D0;
    padding-bottom: 20px;

    a {
      color: palette(brand, blue);


      .text {
        text-decoration: underline;
      }

      span {
        margin-right: 20px;
      }

      &:hover {
        color: palette(brand, purple)
      }
    }
  }

  ul li {
    display: inline-block;
    margin-right: 15px;
  }

  .info__inner {
    padding-top: 20px;
  }

  .info__item {
    position: relative;

    h2 {
      font-size: 1.8rem;
      opacity: .5;
      font-weight: 500;
      margin-bottom: 0;
    }

    &--icon {
      position: relative;
      padding-left: 40px;

      .icon {
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
}

.block-form-info {
  color: white;

  .left-bar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .info__item {

    h2 {
      font-size: 1.8rem;
      opacity: .5;
      font-weight: 500;
      margin-bottom: 0;
    }

    &--icon {
      position: relative;
      padding-left: 40px;

      .icon {
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
}


.block-quote {
  position: relative;
  z-index: 4;
  background-color: palette(brand, purple);
  color: white;
  margin-top: 50px;

  .quote__info {
    position: relative;
    padding-top: 50px;
    padding-bottom: 25px;
  }

  .wrapper {
  }

  .icon {
    position: absolute;
    top: 0;
    left: gutter(1.5);
    transform: translateY(-50%);

    &,
    & > svg {
      width: 50px;
      height: 50px;
    }
  }
}

.block-pullout {
  position: relative;
  background-color: white;
  padding: 40px 20px 20px;
  border-bottom-left-radius: 10px;

  &::before, &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100vw;
  }

  &::after {
    background-color: white;
    bottom: 0;
    left: 100%;
  }

  &::before {
    left: 0;
    height: 10px;
    background-color: palette(brand, blue);
    z-index: 1;
  }

  &--private {
    &::before {
      background-color: palette(brand, private);
    }
  }
}

.block-details {

  .content {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 10px;
      background-color: palette(brand, blue);
      z-index: 1;
    }
  }

  .image {
    position: relative;
    margin-top: -4vh;

    .tag {
      color: white;
      font-size: 1.6rem;
      position: absolute;
      font-weight: bold;
      top: 0;
      left: 0;
      padding: 15px 65px 15px 20px;
      clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
      background-repeat: no-repeat;
      background-position: right 30px center;
      background-size: 16px;
      background-image: url('/dist/img/icons/star-white.svg');
    }
  }

  &--left {
    .content {

      &::after {
        right: 0;
      }

      &::before {
        right: 100%;
      }
    }
  }

  &--right {
    .content {

      &::after {
        left: 0;
      }

      &::before {
        left: 100%;
      }
    }
  }

}

.logo-wall {
  img {
    padding: 10px 5px;
  }
}

.block-news {
  position: relative;
  padding-bottom: 160px;


  .news__cover {
    opacity: 0;
    transition: opacity .3s;

    &--active {
      opacity: 1;
    }
  }

  .tag {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: inline-block;
    background-color: white;
    color: palette(blue);
    padding: 10px;
  }

  .g {
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 20px;
  }

  .tile-news__hold {
    margin-bottom: 0;
  }


  .text {
    overflow: hidden;
    max-height: 0;
    transition: max-height .3s;
  }

  .tile-news--large {
    background-color: transparent;
    position: relative;

    h3 {
      margin-bottom: 15px;
    }

    .read {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0 20px;
    }

    .text {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: none;

      .text {
        max-height: 96px;
      }
    }
  }

  .tile__text {
    background-color: palette(grey, x-light);
    min-height: 224px;
    padding: 20px 20px 60px 20px !important;
  }

  .tile__image {
    img {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.media {
  margin-bottom: 20px;
  border-bottom: 5px solid #F3F3F3;
  font-size: 1.6rem;

  img {
    width: 100px;
    float: left;
    margin-right: 20px;
  }

  .media__body {
    > * {
      padding-bottom: 5px;
    }
  }


  &::after, .media .media__body:after {
    content: "";
    display: table;
    clear: both;
  }
}

.box {
  padding: 30px;
}

.piping {
  border-top: solid 6px;
}

.block-icon {
  box-shadow: 0 4px 20px -5px #221C35;
  padding: 20px;

  .block-icon__item {
    position: relative;
    margin-bottom: 25px;
    padding-left: 115px;
    padding-top: 15px;
    min-height: 90px;

    &--none {
      padding-left: 0;
      text-align: center;
    }

    .icon-block__icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 90px;
      height: 90px;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .icon-block__small {
    font-size: 1.6rem;
  }

  .icon-block__large {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.2;
  }
}

.img--grid--people {
  font-size: 0;

  .btn__hold {
    position: relative;
    border-top: 4px solid palette(brand, blue);

    .btn {
      position: absolute;
      top: -2px;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

@include breakpoint(small) {

  .block-regional-map {
    height: 100%;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .quote__large {
    .quote {
      * {
        font-size: 2.6rem;
      }
    }
  }

  .block-pullout {
    margin-top: -100px;
    padding: 40px 10px 30px 30px;
    box-shadow: 0 0 20px 0 rgba(34, 28, 53, 0.50);
  }

  .hero__icon-block {
    position: relative;
    padding-bottom: 60px;
  }

  .block-icon {
    margin-right: 2rem;
    margin-left: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    padding: 30px 50px 40px;

    .block-icon__item {
      margin-bottom: 0;
    }

    .icon-block__small {
      font-size: 1.6rem;
    }

    .icon-block__large {
      font-size: 4rem;
    }
  }

  .block-intro {

    .intro-content {
      padding: 40px 40px 40px 30px;
      margin-top: -100px;
    }

    .intro-slider {
      padding-top: 60px;
      margin-left: -50px;

      &--side {
        padding-top: 0;
        margin-left: 0;
      }

      &--pull-up {
        padding-top: 0;
        margin-left: 0;
        padding-left: 20px;
      }
    }


    &--netzero {
      .intro-slider {
        margin-left: 0;
        padding-top: 30px;
      }

    }
  }

  .block-info {
    padding: 40px 60px;

    .info__head {
      padding-bottom: 30px;
    }

    .info__inner {
      padding-top: 30px;
    }

    h1 {
      margin-bottom: 35px;
    }
  }

  .block-logo-slider {

    .controls {
      height: 100%;
      padding-bottom: 0;
      text-align: center;
      border-left: 2px solid #A7A7A9;
      border-right: 2px solid #A7A7A9;
      display: flex;
      justify-content: center;
    }

    .slide {
      > * {
        padding: 0 30px;
      }
    }

    .logo-slider {
      padding: 40px 0;
    }
  }

  .block-quote {

    .icon {
      margin-left: gutter(1.5);
      left: 25%;

      &--move {
        left: 12.5%;
      }
    }


    .gs__1-4 {
      position: relative;
    }

    .quote__img {
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
    }
  }

  .block-details {

    .image {
      position: relative;
      z-index: 3;
      margin-top: 0;
      transform: scale(1.1);
    }

    &--left {
      .content {
        padding-right: 60px;
        padding-left: 25px;
      }

      .image {
        transform-origin: right center;
      }
    }

    &--right {
      .content {
        padding-left: 60px;
        padding-right: 25px;
      }

      .image {
        transform-origin: left center;
      }
    }

  }
}

@include breakpoint(medium) {

  .quote__large {

    .quote {
      * {
        font-size: 3.2rem;
      }
    }
  }

  .block-details {
    position: relative;
    overflow: hidden;

    .content {
      border-radius: 10px;
      min-height: 465px;
      overflow: hidden;
      position: relative;

      &::after {
        content: "";
        top: 0;
        position: absolute;
        height: 200%;
        width: 65px;
        background-color: palette(brand, blue);
      }
    }

    &--left {
      .content {
        padding-left: 80px;
        padding-right: 70px;

        &::after {
          left: 0;
          right: auto;
          border-right: 3px solid white;
          transform: rotate(-4deg);
        }
      }
    }

    &--right {
      .content {
        padding-left: 80px;
        padding-right: 70px;

        &::after {
          left: auto;
          right: 0;
          border-left: 3px solid white;
          transform: rotate(4deg);
        }
      }
    }
  }

  .block-icon {
    padding: 40px 50px 50px;
  }

  .logo-wall {
    img {
      padding: 15px 40px;
    }
  }

  .block-pullout {
    padding: 60px 60px 30px;
    margin-top: -70px;

    ul {
      li {
        display: inline-block;
        width: 50%;
        vertical-align: top;
      }
    }
  }

  .block-intro {

    .intro-content {
      padding: 50px;
    }

    .intro-slider {

      &--pull-up {
        margin-top: -12vh;
      }
    }

    &--netzero {
      .intro-slider {
        padding-top: 0;
      }
    }
  }

  .block-grid {

    .block__image {
      min-height: 420px;
    }
  }

  .block-info {
    ul {

      margin-top: 10px;

      li {
        display: block;
        margin-right: 0;
      }
    }
  }

  .block-form-info {
    overflow: hidden;

    .right-side {
      padding-right: 20px;
      padding-left: 60px;
    }

    .left-bar {
      position: relative;
      padding-bottom: 6vh;

      &::before {
        content: "";
        position: absolute;
        width: 100vw;
        top: 0;
        bottom: 0;
        right: 100%;
        background-color: #1B162B;
      }
    }
  }

  .block__info--find-full {
    padding: 50px 100px;
  }

  .block-logo-slider {

    .logo-slider {
      padding: 70px 0;
    }

    .blue-arrows {
      padding: 0;

      .flickity-prev-next-button {

        &.previous {
          left: auto;
          right: 12.5%;
        }
      }
    }


    .slide {

      > * {
        padding: 0 10px;
        margin-bottom: 0;
      }
    }
  }

  .block-quote {

    &::before {
      content: "";
      background-color: palette(blue);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 50px;
      clip-path: polygon(33% 0, 100% 0, 100% 100%, 0% 100%);
    }

    .icon {
      left: 33.333%;

      &--move {
        left: 16.5%;
      }
    }

    .quote__info {
      padding-right: 80px;

      .quote {
        min-height: 150px;
      }
    }
  }
}

@include breakpoint(large) {

  .block-details {


    &--left {
      .content {
        padding-left: 90px;
        padding-right: 80px;

      }
    }

    &--right {
      .content {
        padding-left: 90px;
        padding-right: 80px;
      }
    }
  }

  .block-intro {

    .intro-content {
      padding: 50px;

      .inner {
        padding-right: 30px;
      }
    }

    .intro-slider {
      margin-left: -70px;

      &--side {
        margin-left: 0;
      }

      &--pull-up {
        margin-left: 0;
      }
    }

    &--netzero {
      .intro-slider {
        margin-left: 0;
      }
    }
  }

  .block-logo-slider {

    .slide {

      > * {
        padding: 0 20px;
        margin-bottom: 0;
      }
    }
  }

  .block-quote {
    .icon {
      left: 25%;

      &--move {
        left: 12.5%;
      }
    }

    &::before {
      width: 150px;
    }
  }

  .block-details {

    .content {
      padding-top: 30px;
      padding-bottom: 30px;
      min-height: 520px;
    }
  }
}

.media--cutout-partner {
  position: relative;
  min-height: 120px;
  padding-left: 120px;

  .media__img {
    position: absolute;
    left: 0;
    top: 0;
  }
}