// ==========================================================================
// Preview
// ==========================================================================
.preview--block {
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  margin-bottom: 25px;
  clear: left;
  width: 100%;

  .service-logo {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    img {
      width: auto;
      max-width: 230px;
      max-height: 70px;
      padding: 0;
    }
  }

  a {
    z-index: 2;
    position: relative;
    display: block;
    max-height: 450px;
    border-radius: 10px;
    overflow: hidden;
    transition: transform .3s, box-shadow .3s;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      @include breakpoint(small) {
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
      }
    }
  }


  .text {
    background: black;
    padding: 15px 105px 15px 15px;

    .tagline, .medium {
      margin-bottom: 5px;
    }

    .h3 {
      font-size: 1.8rem;
      margin-bottom: 0;

      @include breakpoint(small) {
        font-size: 2.2rem;
      }
    }


    @include breakpoint(small) {
      padding: 25px 105px 25px 25px;
      background: transparent;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  .read {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 70px;
    padding: 20px 0;
    transition: clip-path .3s;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-left: 3px solid white;
      background: palette(blue);
      transform: rotate(3deg);
      width: 200%;
      height: 200%;
      transition: transform .3s;
    }

    .read__inner {
      text-align: center;
      position: absolute;
      bottom: 25px;
      width: 100%;
    }

    .icon {
      margin-top: 10px;
    }
  }

  &--private {
    .read::before {
      background-color: #5F2167;
    }
  }

  &__large {
    width: 100%;
  }


  &:hover {
    a {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
    }

    .read {
      &::before {
        transform: translateX(-30px) rotate(3deg);
      }
    }

    .icon {
      transform: none !important;
    }
  }
}

