//=====================================
// #COOKIE
//=====================================
#cookie-message {
  background-color: palette(brand, purple);
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 10000;

  p {
    color: white;
  }

  p a {
    color: white;
    text-decoration: underline;
  }

  .wrapper {
    padding: gutter(2) gutter(1);
  }
}

@include breakpoint(medium) {
  #cookie-message {


    .wrapper {
      padding: 40px;
    }
  }
}