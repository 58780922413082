/* Parent element can be any width and height */
.parent {
  position: fixed;
  z-index: 99999;
  width: 100%; /* can be any width */
  height: 100%;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.is-visible){
    display: none;
  }
}

.parent:before { /* pseudo element to force vertical centering of child element */
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.parent:after { /* pseudo element to create overlay */
  background-color: palette(brand, purple);
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

/* Centered child element can be any width and height */
.centeredchild {
  position: relative; /* position element to participate in z-indexing */
  z-index: 20; /* higher z-index than overlay */
  display: inline-block;
  vertical-align: middle;
  width: 90%; /* can be any width */

  @include breakpoint(small) {
    width: 70%; /* can be any width */
  }
}

.centeredchild__content {
  position: relative;
  background: #FFFFFF;
  padding: 12px;
  border-radius: 8px;

  .centeredchild__dismiss {
    z-index: 11;
  }

  .centeredchild__title {
    font-size: 2.6rem;
    font-weight: bold;
  }
}

/* Video container to maintain Youtube 16:9 aspect ratio */
.video {
  position: relative;
  padding-top: 25px;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

/* Make Youtube IFRAME responsive */
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}