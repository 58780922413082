.navigation-burger {
  display: inline-block;
  position: relative;
  z-index: 10;
  background-color: #221C34;
  border-radius: 50%;
  padding: 15px 12px;
  height: 46px;
  width: 46px;
  transition: background-color .3s;

  &--invert {
    background-color: #009ABF;
  }
}

.is-open .navigation-burger {
  background-color: #009ABF;

  span, button::before, button::after {
    border-color: palette(brand, purple) !important;
  }

  &--invert {
    //background-color: #221C34;
  }
}

.hamburger {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 16px;
  margin: 0;
  position: relative;
  width: 100%;
  outline: none;



  span {
    height: 0;
    border-bottom: 2px solid white;
    width: 70%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    transition: all 200ms ease-out;
    backface-visibility: hidden;
  }

  &::after, &::before {
    transform: translateY(-50%);
    position: absolute;
    border-bottom: 2px solid white;
    content: '';
    height: 0;
    width: 100%;
    right: 0;
    transition: all .3s ease-out;
  }

  &:hover {
    span {
      width: 80%;
    }
  }

  &--invert {
    span, &::after, &::before {
      border-color: #221C34;
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    top: 100%;
  }

  &.open {

    &::before {
      top: 50%;
      transform: rotate(-45deg);
    }

    &::after {
      top: 50%;
      transform: rotate(45deg);
    }

    span {
      width: 100%;
      transform: rotate(-45deg);
    }
  }
}

@include breakpoint(small) {
  .navigation__buttons {
    display: none;
  }
}

@include breakpoint(medium) {
  .navigation-burger {
    padding: 12px 12px;
  }
}
