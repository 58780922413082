.sidebar__group {
  background-color: palette(brand, purple);
  color: white;
  border-top: 5px solid palette(brand, blue);
  padding-top: 40px;
  padding-bottom: 40px;

  .inner {
    padding: 0 25px;

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .text {
    padding-top: 25px;
  }
}

.sidebar-blocks {
  max-width: 370px;
  margin: 0 auto;

  .sidebar__image {
    font-size: 0;
  }

  .sidebar__contact {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    overflow: hidden;

    .inner {
      padding: 30px 120px 50px 20px;
      position: relative;
      overflow: hidden;
    }

    .bottom {
      border-top: 3px solid white;
      padding: 30px;
      font-size: 1.6rem;
    }

    .cutout {
      font-size: 0;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 200;
      width: 140px;
      transform: scaleX(-1) translateX(-20px);
    }

    .message {
      padding: 27px 20px;
      text-align: right;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      position: absolute;
      top: -1px;
      right: -1px;
      width: 70px;
      bottom: -1px;
      will-change: clip-path;
      clip-path: polygon(30% 0%, 100% 0, 100% 100%, 0% 100%);
      transition: clip-path .3s, width .3s;
      background-color: palette(brand, blue);
    }


    &--cutout {
      padding-right: 125px;
    }
  }
}

@include breakpoint(medium) {
  .sidebar-blocks {


    .sidebar__contact {
      .cutout {
        width: 145px;
        transform: scaleX(-1) translateX(-40px);
      }

      &--cutout {
        min-height: 230px;
        padding-right: 100px;
      }
    }
  }
}

@include breakpoint(large) {
  .sidebar-blocks {


    .sidebar__contact {
      .cutout {
        width: 165px;
        transform: scaleX(-1) translateX(-40px) translateY(1px);
      }

      &--cutout {
        padding-right: 120px;
      }
    }
  }
}